import { Injectable } from '@angular/core';
import * as parser from 'papaparse';

import { Panmodell } from '../models/pan.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PanserviceService {
  pandefinitions: any;
  panmodell: Panmodell;
  isLoaded: boolean;

  constructor(private http: HttpClient) {
    this.isLoaded = false;
    this.loadCsvData();

  }

  setPanmodell(panmodell) {
    localStorage.clear();
    localStorage.setItem('PanFaktor', JSON.stringify(panmodell));
    this.panmodell = panmodell;
  }

  getPanmodell() {
    if (JSON.parse(localStorage.getItem('PanFaktor')) != undefined && JSON.parse(localStorage.getItem('PanFaktor')) != null) {
      if (this.panmodell == undefined) {
        return null;
      }
      this.panmodell.parse(JSON.parse(localStorage.getItem('PanFaktor')));
      return this.panmodell;
    } else {
      return null;
    }
  }

  debugPanmodell() {
    console.log('--Debug Pan-Factor Modell --');
    console.log(this.panmodell);
  }
  loadCsvData() {
    this.http.get('../assets/panfaktordefinitionen.csv', { responseType: 'text' }).subscribe(
      definitions => {

        if (this.pandefinitions == undefined || this.pandefinitions == null) {
          this.pandefinitions = [];
        }
        //data ist eine zeile
        //Sinn; Qualitaet; Erklaerung
        parser.parse(definitions, {
          header: true, skipEmptyLines: true, complete: (result, file) => {
            this.pandefinitions = result.data;
            this.isLoaded = true;
            // console.log('Load Definitions');
            // console.log(this.pandefinitions);

            // console.log('Pan-Definition');
            // console.log(this.pandefinitions[6].Erklaerung);
          }
        });

      }
    )
  }

}
