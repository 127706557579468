import { Component, OnInit } from '@angular/core';
import { AdviceService } from '../services/advice.service';

@Component({
  selector: 'app-im-olfaktorie',
  templateUrl: './im-olfaktorie.component.html',
  styleUrls: ['./im-olfaktorie.component.css']
})
export class ImOlfaktorieComponent implements OnInit {

  constructor(public adviceService: AdviceService) { }

  ngOnInit() {
    this.adviceService.result_senses.olfaktorisch = false;
    // this.change3II1();
  }

  //Qualität
  change3II1() {
    this.adviceService.result_quality.olfaktorisch.setAll(this.adviceService.result_senses.olfaktorisch);
    this.adviceService.result_display.olfaktorisch.setAll(this.adviceService.result_senses.olfaktorisch);
    this.adviceService.nextStep(13);
  }
  change3II1a1() {
    this.adviceService.nextStep(13);
    this.adviceService.result_quality.olfaktorisch.abstrakt = !this.adviceService.result_quality.olfaktorisch.schematisch;
    this.adviceService.result_quality.olfaktorisch.einfach = !this.adviceService.result_quality.olfaktorisch.schematisch;
    this.adviceService.result_quality.olfaktorisch.realitaetsnah = !this.adviceService.result_quality.olfaktorisch.schematisch;
    this.adviceService.result_quality.olfaktorisch.real = !this.adviceService.result_quality.olfaktorisch.schematisch;

    //Display
    // <!-- Frage 3.II.1.b.1 -->
    // <!-- Verlinkt zu 3 II 1 a 1 -->
    this.adviceService.result_display.olfaktorisch.setAll(false);
    this.adviceService.result_display.olfaktorisch.wfd = this.adviceService.result_quality.olfaktorisch.schematisch;
    // <!-- Frage 3.II.1.b.1.1 -->
    // <!-- Verlinkt zu 3.I.b.1.1.1 -->
    if (this.adviceService.result_display.olfaktorisch.wfd == false) {
      this.adviceService.result_display.olfaktorisch.hhd = !this.adviceService.freeHands;
      this.adviceService.result_display.olfaktorisch.hmd = this.adviceService.freeHands;
    }

  }
  change3II1a11() {
    this.adviceService.result_quality.olfaktorisch.schematisch = false;
    this.adviceService.result_quality.olfaktorisch.einfach = false;
    this.adviceService.result_quality.olfaktorisch.realitaetsnah = !this.adviceService.result_quality.olfaktorisch.abstrakt;
    this.adviceService.result_quality.olfaktorisch.real = !this.adviceService.result_quality.olfaktorisch.abstrakt;
  }

}
