import { Component, OnInit } from '@angular/core';
import { AdviceService } from '../services/advice.service';

@Component({
  selector: 'app-help-short',
  templateUrl: './help-short.component.html',
  styleUrls: ['./help-short.component.css']
})
export class HelpShortComponent implements OnInit {




  constructor(private adviceService: AdviceService) { }

  ngOnInit() {
  }

  setBoolean(variable: boolean, value: boolean) {
    variable = value;
  }

}
