import { Component, OnInit } from '@angular/core';
import { PanserviceService } from '../services/panservice.service';
import { AdviceService } from '../services/advice.service';
import { Panmodell } from '../models/pan.model';

@Component({
  selector: 'app-help-pan',
  templateUrl: './help-pan.component.html',
  styleUrls: ['./help-pan.component.css']
})
export class HelpPanComponent implements OnInit {

  panmodell: Panmodell;
  media: any;

  constructor(private adviceService: AdviceService, private panService: PanserviceService) { }


  ngOnInit() {
    // this.media = 'VR';
  }

  checkStorage(): Boolean {
    if (this.panService.getPanmodell() === undefined || this.panService.getPanmodell() === null) {
      return false;
    } else {
      this.fillAdvice();
      return true;
    }
  }

  fillAdvice() {
    this.panmodell = this.panService.getPanmodell();
    this.adviceService.variante1.panmodell = this.panmodell;
    this.adviceService.category = '' + this.panmodell.Category;
    this.adviceService.name = '' + this.panmodell.Name;

  }

  changeMedia() {
    this.adviceService.result_media.setAll(false);
    switch (this.media) {
      case 'AR':
        this.adviceService.result_media.ar = true;
        break;
      case 'AV':
        this.adviceService.result_media.av = true;
        break;
      case 'VR':
        this.adviceService.result_media.vr = true;
        break;
      case '360Video':
        this.adviceService.result_media.degreeVideo = true;
        break;
      case '360Foto':
        this.adviceService.result_media.degreePhoto = true;
        break;
      case '3D':
        this.adviceService.result_media.threed = true;
        break;
      case 'Video':
        this.adviceService.result_media.video = true;
        break;
      case 'Foto':
        this.adviceService.result_media.photo = true;
        break;
      case '2D':
        this.adviceService.result_media.twod = true;
        break;
    }
    this.adviceService.nextStep(1);
  }




}
