import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as parser from 'papaparse';



@Injectable({
  providedIn: 'root'
})
export class BilanzService {

  bilanzData: any[];

  constructor(private http: HttpClient) { }

  //load all analyized applications and get values 
  loadData() {
    this.http.get('../assets/konfigurator_anwendungen.csv', { responseType: 'text' }).subscribe(
      data => {

        if (this.bilanzData == undefined || this.bilanzData == null) {
          this.bilanzData = [];
        }
        //data ist eine zeile
        //Nr. / Name / AnzahlSinne / Immersion / Präsenz / Interaktion / PanFaktor / Bereich
        parser.parse(data, {
          header: true, skipEmptyLines: true, complete: (result, file) => {
            this.bilanzData = result.data;
          }
        });

      }
    )
  }

  getBilanzData() {
    return this.bilanzData;
  }

  //function to get the maximum, minimum, count and average in an certain category of all applications
  getValues(bereich: String): any {
    var values;
    var min = 100, max = 0, count = 0, average = 0, sum = 0;
    this.bilanzData.forEach(element => {
      var faktor = Number(element.PanFaktor);
      if (element.Bereich == bereich) {
        count++;
        sum += faktor;
        if (min > faktor) {
          min = faktor;
        }
        if (max < faktor) {
          max = faktor;
        }
      }
    });

    average = Math.round(sum / count);
    if (count == 0) {
      min = 0;
      average = 0;
    }
    values = { Minimum: min, Maximum: max, Count: count, Average: average };
    return values;
  }


}
