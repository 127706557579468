import { Ratingmodell } from './rating.model';

export class Immersionmodell {
    _id: String;
    Rating: number;
    CountOfSense: number;
    Visually: Ratingmodell;
    Aurally: Ratingmodell;
    Haptic: Ratingmodell;
    Olfactory: Ratingmodell;
    Gustatory: Ratingmodell;
    constructor(pandefinitions: any) {
        this.Rating = 0;
        this.CountOfSense = 1;
        this.Visually = new Ratingmodell('Schematisch', 'Wenige', pandefinitions);
        this.Aurally = new Ratingmodell('Keine', 'Keine', pandefinitions);
        this.Haptic = new Ratingmodell('Keine', 'Keine', pandefinitions);
        this.Olfactory = new Ratingmodell('Keine', 'Keine', pandefinitions);
        this.Gustatory = new Ratingmodell('Keine', 'Keine', pandefinitions);
        this.CalculateRating();

    }
    parse(obj: any) {
        this.Rating = obj.Rating;
        this.CountOfSense = obj.CountOfSense;
        this.Visually.parse(obj.Visually);
        this.Aurally.parse(obj.Aurally);
        this.Haptic.parse(obj.Haptic);
        this.Olfactory.parse(obj.Olfactory);
        this.Gustatory.parse(obj.Gustatory);
    }

    getString(advice: boolean): string {
        var txt = '';
        txt += this.Visually.getString('Die visuelle Wahrnehmung', 'Visuell', advice);
        txt += this.Aurally.getString('Die auditve Wahrnehmung', 'Auditiv', advice);
        txt += this.Haptic.getString('Die haptische Wahrnehmung', 'Haptisch', advice);
        txt += this.Olfactory.getString('Die olfaktorische Wahrnehmung', 'Olfaktorisch', advice);
        txt += this.Gustatory.getString('Die gustatorische Wahrnehmung', 'Gustatorisch', advice);
        return txt;
    }

    CountUseOfSense() {
        var count = 0;
        if (this.Visually.Quality != 'Keine' && this.Visually.Quality != '') {
            count++;
        }
        if (this.Aurally.Quality != 'Keine' && this.Aurally.Quality != '') {
            count++;
        }
        if (this.Haptic.Quality != 'Keine' && this.Haptic.Quality != '') {
            count++;
        }
        if (this.Olfactory.Quality != 'Keine' && this.Olfactory.Quality != '') {
            count++;
        }
        if (this.Gustatory.Quality != 'Keine' && this.Gustatory.Quality != '') {
            count++;
        }

        this.CountOfSense = count * 2;
        //console.log('Neu berechnete Sinne: ' + count + ' = ' + this.CountOfSense);
    }

    CalculateRating() {
        var rate = 0;
        this.CountUseOfSense();
        rate += this.CountOfSense;
        if (this.Visually.getQualityNumber() * this.Visually.getQuantityNumber() != 0) {
            rate += this.Visually.getQualityNumber() + this.Visually.getQuantityNumber();
        }
        if (this.Haptic.getQualityNumber() * this.Haptic.getQuantityNumber() != 0) {
            rate += this.Haptic.getQualityNumber() + this.Haptic.getQuantityNumber();
        }
        if (this.Aurally.getQualityNumber() * this.Aurally.getQuantityNumber() != 0) {
            rate += this.Aurally.getQualityNumber() + this.Aurally.getQuantityNumber();
        }
        if (this.Olfactory.getQualityNumber() * this.Olfactory.getQuantityNumber() != 0) {
            rate += this.Olfactory.getQualityNumber() + this.Olfactory.getQuantityNumber();
        }
        if (this.Gustatory.getQualityNumber() * this.Gustatory.getQuantityNumber() != 0) {
            rate += this.Gustatory.getQualityNumber() + this.Gustatory.getQuantityNumber();
        }
        this.Rating = rate;
        //console.log('Immersion berechnet: ' + this.Rating + '/60');
    }
    getBetter(step: number) {
        this.Visually.getBetter(step);
        this.Aurally.getBetter(step);
        this.Haptic.getBetter(step);
        this.Olfactory.getBetter(step);
        this.Gustatory.getBetter(step);
    }
}