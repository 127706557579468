import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { GoogleChartsModule } from 'angular-google-charts';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTableModule } from '@angular/material/table';
import { MatSliderModule } from '@angular/material/slider';
import { MatRadioModule } from '@angular/material/radio';
import { MatTreeModule } from "@angular/material/tree";
import { MatProgressBarModule } from "@angular/material/progress-bar";


import { AppComponent } from './app.component';
import { ConfiguratorComponent } from './configurator/configurator.component';
import { ResultComponent } from './result/result.component';
import { AppRoutingModule } from './app-routing.module';
import { DescriptionComponent } from './description/description.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PanfactorComponent } from './panfactor/panfactor.component';
import { HelpComponent } from './help/help.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { FooterComponent } from './footer/footer.component';
import { HelpShortComponent } from './help-short/help-short.component';
import { HelpLongComponent } from './help-long/help-long.component';
import { QtnGeneralComponent } from './qtn-general/qtn-general.component';
import { QtnMediaComponent } from './qtn-media/qtn-media.component';
import { ImmersionComponent } from './immersion/immersion.component';
import { ImVisuellComponent } from './im-visuell/im-visuell.component';
import { ImAuditivComponent } from './im-auditiv/im-auditiv.component';
import { ImHaptikComponent } from './im-haptik/im-haptik.component';
import { ImOlfaktorieComponent } from './im-olfaktorie/im-olfaktorie.component';
import { ImGustatorieComponent } from './im-gustatorie/im-gustatorie.component';
import { PraesenzComponent } from './praesenz/praesenz.component';
import { PraeBodyComponent } from './prae-body/prae-body.component';
import { PraeMovementComponent } from './prae-movement/prae-movement.component';
import { InteraktionComponent } from './interaktion/interaktion.component';
import { IntCommunicationComponent } from './int-communication/int-communication.component';
import { IntInteractionComponent } from './int-interaction/int-interaction.component';
import { HelpPanComponent } from './help-pan/help-pan.component';

@NgModule({
  declarations: [
    AppComponent,
    ConfiguratorComponent,
    ResultComponent,
    DescriptionComponent,
    NavbarComponent,
    PanfactorComponent,
    HelpComponent,
    ImpressumComponent,
    DatenschutzComponent,
    FooterComponent,
    HelpShortComponent,
    HelpLongComponent,
    QtnGeneralComponent,
    QtnMediaComponent,
    ImmersionComponent,
    ImVisuellComponent,
    ImAuditivComponent,
    ImHaptikComponent,
    ImOlfaktorieComponent,
    ImGustatorieComponent,
    PraesenzComponent,
    PraeBodyComponent,
    PraeMovementComponent,
    InteraktionComponent,
    IntCommunicationComponent,
    IntInteractionComponent,
    HelpPanComponent
  ],
  imports: [
    GoogleChartsModule.forRoot(),
    FormsModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatCardModule,
    MatIconModule,
    MatExpansionModule,
    MatDividerModule,
    MatToolbarModule,
    MatSliderModule,
    MatButtonModule,
    MatRadioModule,
    MatMenuModule, MatTreeModule, MatProgressBarModule,
    MatListModule, MatTooltipModule, MatGridListModule,
    MatStepperModule, MatFormFieldModule, MatInputModule, MatSelectModule, AppRoutingModule,
    MatTableModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  sences: string[] = ['Visuelle Wahrnehmung', 'Auditive Wahrnehmung', 'Haptische Wahrnehmung', 'Olfaktorische Wahrnehmung', 'Gustatorische Wahrnehmung'];
}
