export class SenseQualityModel {
    visuell: QualityModel;
    auditiv: QualityModel;
    haptisch: QualityModel;
    olfaktorisch: QualityModel;
    gustatorisch: QualityModel;
    koerpergefuehl: QualityModel;
    bewegung: QualityModel;
    kommunikation: QualityModel;
    interaktion: QualityModel;
    constructor() {
        this.visuell = new QualityModel();
        this.auditiv = new QualityModel();
        this.haptisch = new QualityModel();
        this.olfaktorisch = new QualityModel();
        this.gustatorisch = new QualityModel();
        this.koerpergefuehl = new QualityModel();
        this.bewegung = new QualityModel();
        this.kommunikation = new QualityModel();
        this.interaktion = new QualityModel();
    }
    reset() {
        this.visuell.setAll(null);
        this.auditiv.setAll(null);
        this.haptisch.setAll(null);
        this.olfaktorisch.setAll(null);
        this.gustatorisch.setAll(null);
        this.koerpergefuehl.setAll(null);
        this.bewegung.setAll(null);
        this.kommunikation.setAll(null);
        this.interaktion.setAll(null);
        // this.visuell.setAll(false);
        // this.auditiv.setAll(false);
        // this.haptisch.setAll(false);
        // this.olfaktorisch.setAll(false);
        // this.gustatorisch.setAll(false);
        // this.koerpergefuehl.setAll(false);
        // this.bewegung.setAll(false);
        // this.kommunikation.setAll(false);
        // this.interaktion.setAll(false);
    }
    loadDemo() {
        //how it would be in vr4teach
        this.visuell.realitaetsnah = true;
        this.auditiv.einfach = true;
        this.haptisch.schematisch = true;
        this.koerpergefuehl.schematisch = true;
        this.bewegung.einfach = true;
        this.interaktion.einfach = true;
        this.kommunikation.abstrakt = true;
    }
}
export class QualityModel {
    schematisch: boolean;
    einfach: boolean;
    abstrakt: boolean;
    realitaetsnah: boolean;
    real: boolean;
    constructor() {
        this.schematisch = true;
        this.einfach = true;
        this.abstrakt = true;
        this.realitaetsnah = true;
        this.real = true;

    }
    setAll(value: boolean) {
        this.schematisch = value;
        this.einfach = value;
        this.abstrakt = value;
        this.realitaetsnah = value;
        this.real = value;
    }

}

export class SensesModel {
    visuell: boolean;
    auditiv: boolean;
    haptisch: boolean;
    olfaktorisch: boolean;
    gustatorisch: boolean;
    constructor() {
        this.visuell = true;
        this.auditiv = true;
        this.haptisch = true;
        this.olfaktorisch = false;
        this.gustatorisch = false;
    }
    setAll(value: boolean) {
        this.visuell = value;
        this.auditiv = value;
        this.haptisch = value;
        this.olfaktorisch = value;
        this.gustatorisch = value;
    }

    reset() {
        this.setAll(null);
        // this.setAll(false);
        // this.visuell = true;
    }
    loadDemo() {
        //how it would be in VR4Teach
        this.reset();
        this.visuell = true;
        this.auditiv = true;
        this.haptisch = true;
    }
}