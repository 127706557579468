import { Panmodell } from './pan.model';

export class VarianteModel {
    media: TooltipModel;
    qualities: TypModel;
    typ: TypModel;
    panmodell: Panmodell;
    minPanmodell: Panmodell;
    averagePanfactor: number;
    pantext: string;
    constructor(pandefinitons: any) {
        this.media = new TooltipModel();
        this.typ = new TypModel();
        this.qualities = new TypModel();
        this.panmodell = new Panmodell('Beispielanwendung', 'Ausbildung', pandefinitons);
        this.minPanmodell = new Panmodell('MinPanmodell', 'Ausbildung', pandefinitons);
        this.pantext = 'Es gibt keinen Vergleich zu anderen Anwendungen.';
        this.averagePanfactor = 0;
    }
    reset() {
        this.media.reset();
        this.typ.clear();
        this.qualities.clear();
        this.panmodell.reset();
        this.minPanmodell.reset();
        this.pantext = '';
        this.averagePanfactor = 0;
    }

    loadDemo() {
        //how would it be in vr4Teach
        this.averagePanfactor = 0;
        this.media.loadDemo();
        this.typ.loadDemo();
        this.qualities.loadDemo();
        this.panmodell.loadDemo();
        this.minPanmodell.loadDemo();
        this.pantext = 'Es gibt keinen Vergleich zu anderen Anwendungen.';
    }
}

export class TypModel {
    visuell: TooltipModel[];
    auditiv: TooltipModel[];
    haptisch: TooltipModel[];
    olfaktorisch: TooltipModel[];
    gustatorisch: TooltipModel[];
    koerpergefuehl: TooltipModel[];
    bewegung: TooltipModel[];
    constructor() {
        this.visuell = [new TooltipModel()];
        this.auditiv = [new TooltipModel()];
        this.haptisch = [new TooltipModel()];
        this.olfaktorisch = [new TooltipModel()];
        this.gustatorisch = [new TooltipModel()];
        this.koerpergefuehl = [new TooltipModel()];
        this.bewegung = [new TooltipModel()];
    }
    clear() {
        this.visuell = [];
        this.auditiv = [];
        this.haptisch = [];
        this.olfaktorisch = [];
        this.gustatorisch = [];
        this.koerpergefuehl = [];
        this.bewegung = [];
    }
    loadDemo() {
        //how it would be in vr4teach
    }
}

export class TooltipModel {
    text: string;
    tooltip: string;
    constructor() {
        this.text = 'Noch nicht ermittelt';
        this.tooltip = 'Keine weiteren Informationen vorhanden.';
    }
    newTooltipModel(text: string, tooltip: string): TooltipModel {
        this.text = text;
        this.tooltip = tooltip;
        return this;
    }
    reset() {
        this.text = 'Konnte nicht ermittelt werden.';
        this.tooltip = 'Keine weiteren Informationen vorhanden. Die Fragen diesbezüglichs wurden noch nicht beantwortet.';
    }

    loadDemo() {
        //how it would be in vr4teach
        this.text = 'VR ist das beste Medium.';
        this.tooltip = 'Nicht ausgefüllt.';
    }
}