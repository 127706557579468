export class Ratingmodell {
    pandefinitions: any;
    _id: String;
    Quality: String;
    Quantity: String;
    constructor(quality: String, quantity: String, pandefinitions: any) {
        this.pandefinitions = pandefinitions;
        this.Quality = quality;
        this.Quantity = quantity;
    }

    parse(obj: any) {
        this.pandefinitions = obj.pandefinitions;
        this.Quality = obj.Quality;
        this.Quantity = obj.Quantity;
    }

    getString(form: string, sinn: string, advice: boolean): string {
        var txt = '';
        //text for quality
        if (this.Quality == 'Keine') {
            txt = form + ' wird nicht umgesetzt. ';
        } else {
            if (advice) {
                txt = form + ' sollte in einer Umsetzung der Qualität "' + this.Quality + '" in einer Quantität von "' + this.Quantity + '" erfolgen.\n Nähere Erläuterung: \n' + this.getDescription(sinn, this.Quality) + '.\n\n ';
            } else {
                txt = form + ' ist in einer Umsetzung der Qualität "' + this.Quality + '" in einer Quantität von "' + this.Quantity + '" ermittelt worden.\n Nähere Erläuterung:\n ' + this.getDescription(sinn, this.Quality) + '.\n\n ';
            }
        }
        return txt;
    }

    getSum() {
        return this.getQualityNumber() + this.getQuantityNumber();
    }

    getDescription(sinn: string, quality: String) {
        var txt = 'Keine weitere Definition vorhanden.'
        if (this.pandefinitions != undefined) {
            for (let i = 0; i < this.pandefinitions.length; i++) {
                const e = this.pandefinitions[i].Sinn;
                if (e == sinn) {
                    if (this.pandefinitions[i].Qualitaet == quality) {
                        txt = this.pandefinitions[i].Erklaerung;
                    }
                }
            }
        } else {
            txt = 'Pan-Faktor Definition konnten nicht erfolgreich geladen und durchsucht werden.';
        }
        return txt;
    }

    getBetter(step: number) {
        if (this.Quantity == 'Vollständig') {
            //Quanität is almost the highest --> change Quality
            return new Ratingmodell(this.getBetterQuality(step), this.Quantity, this.pandefinitions);
        } else {
            //Raise quanität to full for change
            this.Quantity = 'Vollständig';
            // this.Quality = this.getBetterQuality(step);
            return new Ratingmodell(this.Quality, 'Vollständig', this.pandefinitions);
        }
    }

    getBetterQuality(step: number) {
        var defintions = ['Keine', 'Schematisch', 'Einfach', 'Abstrakt', 'Realitätsnah', 'Real'];
        var count = this.getQualityNumber();
        if (count != 5) {
            return defintions[count + step];
        } else {
            return defintions[count];
        }
    }

    // getBetterQuantity(step: number) {
    //     var defintions = ['Keine', 'Wenige', 'Einige', 'Viele', 'Sehr Viele', 'Vollständig'];
    //     var count = this.getQuantityNumber();
    //     if ((defintions.length - 1) < (count + step)) {
    //         return defintions[count + step];
    //     } else if ((defintions.length - 1) < (count + step - 1)) {
    //         return defintions[count + step - 1];
    //     } else {
    //         return defintions[count];
    //     }
    // }

    getQualityNumber() {
        switch (this.Quality) {
            case 'Keine':
                return 0;
            case 'Schematisch':
                return 1;
            case 'Einfach':
                return 2;
            case 'Abstrakt':
                return 3;
            case 'Realitätsnah':
                return 4;
            case 'Real':
                return 5;
            default:
                return 0;
        }
    }
    getQuantityNumber() {
        switch (this.Quantity) {
            case 'Keine':
                return 0;
            case 'Wenige':
                return 1;
            case 'Einige':
                return 2;
            case 'Viele':
                return 3;
            case 'Sehr Viele':
                return 4;
            case 'Vollständig':
                return 5;
            default:
                return 0;
        }
    }
}