import { Component, OnInit } from '@angular/core';
import { AdviceService } from '../services/advice.service';

@Component({
  selector: 'app-im-auditiv',
  templateUrl: './im-auditiv.component.html',
  styleUrls: ['./im-auditiv.component.css']
})
export class ImAuditivComponent implements OnInit {

  helpBool: boolean;
  helpBool2: boolean;
  constructor(public adviceService: AdviceService) { }

  ngOnInit() {
    this.helpBool = null;
    this.helpBool2 = null;
    // //Qualität
    // this.change3II1a1();
    // this.change3II1a12();
    // this.change3II1a11();
    // this.change3II1a111();
    // //Displayarten
    // this.change3II1b1();
    // // this.change3II1b11();
  }

  //Qualität
  change3II1() {
    //Kein Auditives Feedback --> Alle ein oder aussschalten
    this.adviceService.result_quality.auditiv.setAll(this.adviceService.result_senses.auditiv);
    this.adviceService.result_display.auditiv.setAll(this.adviceService.result_senses.auditiv);
    this.adviceService.nextStep(8);
  }
  change3II1a1() {
    //kein einfach oder schematisch
    this.adviceService.result_quality.auditiv.schematisch = !this.helpBool;
    this.adviceService.result_quality.auditiv.einfach = !this.helpBool;
    this.adviceService.result_quality.auditiv.abstrakt = this.helpBool;
    this.adviceService.result_quality.auditiv.realitaetsnah = this.helpBool;
    this.adviceService.result_quality.auditiv.real = this.helpBool;
  }
  change3II1a12() {
    //Einfach oder Schematisch
    this.adviceService.result_quality.auditiv.schematisch = !this.adviceService.result_quality.auditiv.einfach;
    this.adviceService.nextStep(8);
  }
  change3II1a11() {
    //Abstrakt gewählt oder weiter
    this.adviceService.result_quality.auditiv.real = !this.adviceService.result_quality.auditiv.abstrakt;
    this.adviceService.result_quality.auditiv.realitaetsnah = !this.adviceService.result_quality.auditiv.abstrakt;
    this.adviceService.nextStep(8);
  }
  change3II1a111() {
    this.adviceService.result_quality.auditiv.realitaetsnah = !this.adviceService.result_quality.auditiv.real;
  }


  //Displayarten
  change3II1b1() {
    this.adviceService.nextStep(9);
    //NUR WFD
    this.adviceService.result_display.auditiv.wfd = this.helpBool2;
    this.adviceService.result_display.auditiv.surround = this.helpBool2;
    this.adviceService.result_display.auditiv.fishtank = this.helpBool2;

    this.adviceService.result_display.auditiv.bodymounted = !this.adviceService.result_display.auditiv.wfd;
    // this.adviceService.result_display.auditiv.earmounted = !this.adviceService.result_display.auditiv.wfd;
    this.adviceService.result_display.auditiv.earmounted = !this.adviceService.result_display.auditiv.wfd;
    this.adviceService.result_display.auditiv.inear = !this.adviceService.result_display.auditiv.wfd;
    this.adviceService.result_display.auditiv.overear = !this.adviceService.result_display.auditiv.wfd;
    this.adviceService.result_display.auditiv.hhd = !this.adviceService.result_display.auditiv.wfd;
    this.adviceService.result_display.auditiv.hmd = !this.adviceService.result_display.auditiv.wfd;
    this.adviceService.result_display.auditiv.natural = !this.adviceService.result_display.auditiv.wfd;
    this.adviceService.result_display.auditiv.obd = !this.adviceService.result_display.auditiv.wfd;
    this.adviceService.result_display.auditiv.seethrough = !this.adviceService.result_display.auditiv.wfd;
    this.adviceService.result_display.auditiv.nonseethrough = !this.adviceService.result_display.auditiv.wfd;
    //<!-- Frage 3 II 1 b 1 1 -->
    //<!-- Verlinkt zu 3 I b 1 1 1 -->
    if (this.adviceService.result_display.auditiv.wfd == false) {
      this.adviceService.result_display.auditiv.earmounted = this.adviceService.freeHands;
      this.adviceService.result_display.auditiv.inear = this.adviceService.result_display.auditiv.earmounted;
      this.adviceService.result_display.auditiv.overear = this.adviceService.result_display.auditiv.earmounted;
      this.adviceService.result_display.auditiv.hhd = !this.adviceService.result_display.auditiv.earmounted;
      this.adviceService.result_display.auditiv.bodymounted = false;
      this.adviceService.result_display.auditiv.wfd = false;
      this.adviceService.result_display.auditiv.fishtank = false;
      this.adviceService.result_display.auditiv.surround = false;
      this.adviceService.result_display.auditiv.hmd = false;
      this.adviceService.result_display.auditiv.natural = false;
      this.adviceService.result_display.auditiv.seethrough = false;
      this.adviceService.result_display.auditiv.nonseethrough = false;
    }

  }

}
