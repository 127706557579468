import { Component, OnInit } from '@angular/core';
import { AdviceService } from '../services/advice.service';

@Component({
  selector: 'app-int-interaction',
  templateUrl: './int-interaction.component.html',
  styleUrls: ['./int-interaction.component.css']
})
export class IntInteractionComponent implements OnInit {

  helpBoolean: boolean;
  helpBoolean2: boolean;

  constructor(private adviceService: AdviceService) { }

  ngOnInit() {
    this.helpBoolean = null;
    this.helpBoolean2 = null;
    this.adviceService.result_quality.interaktion.setAll(false);
  }
  change5II1() {
    this.adviceService.nextStep(22);
  }
  change5II11() {
    if (this.helpBoolean2) {
      this.adviceService.result_quality.interaktion.einfach = false;
      //Frage verknüpft mit AV
      //5.II.1.1.1
      if (this.adviceService.result_media.av) {
        this.adviceService.result_quality.interaktion.real = true;
        this.adviceService.result_quality.interaktion.realitaetsnah = true;
        this.adviceService.result_quality.interaktion.abstrakt = false;
      } else {
        this.adviceService.result_quality.interaktion.real = false;
        this.adviceService.result_quality.interaktion.realitaetsnah = false;
        this.adviceService.result_quality.interaktion.abstrakt = true;
      }
    } else {
      this.adviceService.result_quality.interaktion.einfach = true;
    }
  }

  change5II111() {
    this.adviceService.result_quality.interaktion.einfach = !this.adviceService.result_quality.interaktion.schematisch;
  }

}
