import { Ratingmodell } from './rating.model';

export class Presensemodell {
    _id: String;
    Rating: number;
    Bodyawarness: Ratingmodell;
    Movement: Ratingmodell;

    constructor(pandefinitons: any) {
        this.Rating = 0;
        this.Bodyawarness = new Ratingmodell('Keine', 'Keine', pandefinitons);
        this.Movement = new Ratingmodell('Keine', 'Keine', pandefinitons);
        this.CalculateRating();
    }
    parse(obj: any) {
        this.Rating = obj.Rating;
        this.Bodyawarness.parse(obj.Bodyawarness);
        this.Movement.parse(obj.Movement);
    }

    getString(advice: boolean): string {
        var txt = '';
        txt += this.Movement.getString('Die Bewegung', 'Bewegung', advice);
        txt += this.Bodyawarness.getString('Das Körpergefühl', 'Koerpergefuehl', advice);
        return txt;
    }

    CalculateRating() {
        var rate = 0;
        if (this.Bodyawarness.getQualityNumber() * this.Bodyawarness.getQuantityNumber() != 0) {
            rate += this.Bodyawarness.getQualityNumber() + this.Bodyawarness.getQuantityNumber();
        }
        if (this.Movement.getQualityNumber() * this.Movement.getQuantityNumber() != 0) {
            rate += this.Movement.getQualityNumber() + this.Movement.getQuantityNumber();
        }
        this.Rating = rate;
        //console.log('Präsenz berechnet: ' + this.Rating + '/20');
    }
    getBetter(step: number) {
        this.Bodyawarness.getBetter(step);
        this.Movement.getBetter(step);
    }
}