import { Component, OnInit } from '@angular/core';
import { AdviceService } from '../services/advice.service';

@Component({
  selector: 'app-im-visuell',
  templateUrl: './im-visuell.component.html',
  styleUrls: ['./im-visuell.component.css']
})
export class ImVisuellComponent implements OnInit {

  helpBoolean: boolean;
  constructor(public adviceService: AdviceService) { }

  ngOnInit() {
    this.helpBoolean = null;
    //Set Quality
    // this.change3Ia1();
    // this.change3Ia11();
    // this.change3Ia12();
    // this.change3Ia121();

    // //Set Display
    // this.change3Ib1();
    // this.change3Ib111();
    // this.change3Ib112();

  }

  // Qualität

  change3Ia1() {
    this.adviceService.result_quality.visuell.realitaetsnah = this.helpBoolean;
    this.adviceService.result_quality.visuell.real = this.adviceService.result_quality.visuell.realitaetsnah;
    this.adviceService.result_quality.visuell.abstrakt = !this.adviceService.result_quality.visuell.real;
  }
  change3Ia11() {
    this.adviceService.result_quality.visuell.realitaetsnah = !this.adviceService.result_quality.visuell.real;
    this.adviceService.result_quality.visuell.schematisch = false;
    this.adviceService.result_quality.visuell.einfach = false;
    this.adviceService.result_quality.visuell.abstrakt = false;
    this.adviceService.nextStep(5);
  }
  change3Ia12() {
    this.adviceService.result_quality.visuell.real = false;
    this.adviceService.result_quality.visuell.realitaetsnah = false;
    this.adviceService.result_quality.visuell.einfach = !this.adviceService.result_quality.visuell.schematisch;
    this.adviceService.result_quality.visuell.abstrakt = !this.adviceService.result_quality.visuell.schematisch;
    this.adviceService.nextStep(5);
  }
  change3Ia121() {
    this.adviceService.result_quality.visuell.einfach = !this.adviceService.result_quality.visuell.abstrakt;
  }

  //Displayarten
  change3Ib1() {
    this.adviceService.result_display.visuell.wfd = this.adviceService.result_display.visuell.fishtank;
    this.adviceService.result_display.visuell.obd = !this.adviceService.result_display.visuell.fishtank;
    this.adviceService.result_display.visuell.surround = !this.adviceService.result_display.visuell.fishtank;
    this.adviceService.result_display.visuell.hmd = false;
    this.adviceService.result_display.visuell.seethrough = !this.adviceService.result_display.visuell.fishtank;
    this.adviceService.result_display.visuell.nonseethrough = !this.adviceService.result_display.visuell.fishtank;
    this.adviceService.result_display.visuell.hhd = !this.adviceService.result_display.visuell.fishtank;
    this.adviceService.result_display.visuell.natural = !this.adviceService.result_display.visuell.fishtank;
    this.adviceService.result_display.visuell.earmounted = !this.adviceService.result_display.visuell.fishtank;
    this.adviceService.result_display.visuell.inear = !this.adviceService.result_display.visuell.fishtank;
    this.adviceService.result_display.visuell.bodymounted = !this.adviceService.result_display.visuell.fishtank;
    this.adviceService.result_display.visuell.overear = !this.adviceService.result_display.visuell.fishtank;
    this.adviceService.nextStep(6);
  }
  change3Ib111() {
    this.adviceService.result_display.visuell.wfd = false;
    this.adviceService.result_display.visuell.nonseethrough = false;
    this.adviceService.result_display.visuell.natural = false;
    this.adviceService.result_display.visuell.earmounted = false;
    this.adviceService.result_display.visuell.inear = false;
    this.adviceService.result_display.visuell.overear = false;
    this.adviceService.result_display.visuell.bodymounted = false;
    this.adviceService.result_display.visuell.surround = false;

    this.adviceService.result_display.visuell.hmd = this.adviceService.result_display.visuell.seethrough;
    this.adviceService.result_display.visuell.obd = this.adviceService.result_display.visuell.seethrough;
    this.adviceService.result_display.visuell.hhd = !this.adviceService.result_display.visuell.seethrough;

    //Verlinkung zu allen Fragen 3.II.1.b.1.1; 3.IV.1b.1.1 und 3.V.1.b.1
    this.adviceService.freeHands = this.adviceService.result_display.visuell.seethrough;
  }
  change3Ib112() {
    this.adviceService.result_display.visuell.wfd = this.adviceService.result_display.visuell.surround;
    this.adviceService.result_display.visuell.hmd = !this.adviceService.result_display.visuell.surround;
    this.adviceService.result_display.visuell.obd = !this.adviceService.result_display.visuell.surround;
    this.adviceService.result_display.visuell.nonseethrough = !this.adviceService.result_display.visuell.surround;
    this.adviceService.result_display.visuell.seethrough = !this.adviceService.result_display.visuell.surround;
    this.adviceService.result_display.visuell.natural = false;
    this.adviceService.result_display.visuell.bodymounted = false;
    this.adviceService.result_display.visuell.earmounted = false;
    this.adviceService.result_display.visuell.inear = false;
    this.adviceService.result_display.visuell.overear = false;
    this.adviceService.result_display.visuell.hhd = false;

  }
}
