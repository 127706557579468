import { Component, OnInit } from '@angular/core';
import { AdviceService } from '../services/advice.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {

  checker: any;
  option: string;
  options: any;
  showVariant1: boolean;
  showVariant3: boolean;
  colsCount: number;

  constructor(public adviceService: AdviceService) { }

  ngOnInit() {
    this.colsCount = 3;
    this.showVariant1 = true;
    this.showVariant3 = true;
    this.options = [
      { name: "Anwendungsgestützt", tooltip: "Sie haben bereits eine Anwendung und haben den Pan-Faktor ermittelt? Hier können Sie sehen, wie Ihre Anwendung verbessert werden kann. Zeit circa 5 Minuten." },
      { name: "Kurzes Szenario", tooltip: "Verkürzter Fragebogen für ein schnelles Ergebnis. Zeit circa 8 Minuten." },
      { name: "Detailliertes Szenario", tooltip: "Detaillierter Fragebogen für das genauste Ergebnis. Zeit circa 15 Minuten." },
    ];
    // this.option = "Kurzes Szenario";
    this.option = "Detailliertes Szenario";
    //init the checker 
    this.checker = { calculated: false, calculateable: true };
    this.refresh();
    this.setStepCount();
  }


  setStepCount() {
    switch (this.option) {
      case this.options[0].name:
        this.adviceService.complete.count = 1;
        this.adviceService.option = 1;
        break;
      case this.options[1].name:
        //Kurze Variante
        //2 Allg + 1 Medium
        this.adviceService.complete.count = 3;
        this.adviceService.option = 2;
        break;
      case this.options[2].name:
        //Lange Variante
        //5 Überpunkte+2Allg
        this.adviceService.complete.count = 22;
        this.adviceService.option = 3;
        break;

      default:
        this.adviceService.complete.count = 1;
        break;
    }
    // console.log('Set Step count to ' + this.adviceService.complete.count)
  }

  calculateHelp() {
    this.adviceService.calculateAdvice();
    this.checker.calculated = true;
    this.colsCount = 1;
    if (this.adviceService.variante1.panmodell.Panfactor != 0) {
      this.colsCount++;
      this.showVariant1 = true;
    } else {
      this.showVariant1 = false;
    }
    if (this.adviceService.variante3.panmodell.Panfactor != 0) {
      this.colsCount++;
      this.showVariant3 = true;
    } else {
      this.showVariant3 = false;
    }
  }


  loadDemo() {
    this.adviceService.name = 'VR4Teach';
    this.adviceService.variante2.loadDemo();
    this.adviceService.variante1.loadDemo();
    this.adviceService.variante3.loadDemo();
  }

  refresh() {
    this.adviceService.reset();
  }

  printPDF() {
    this.adviceService.printPDF();
  }

  debug() {
    // this.adviceService.checkValues();
    this.adviceService.debugAdvice();
  }
}
