import { Component, OnInit } from '@angular/core';
import { AdviceService } from '../services/advice.service';

@Component({
  selector: 'app-im-haptik',
  templateUrl: './im-haptik.component.html',
  styleUrls: ['./im-haptik.component.css']
})
export class ImHaptikComponent implements OnInit {

  isFeedback: boolean;
  isBody: boolean;
  isVibration: boolean;
  isBodyAndVibration: boolean;
  isBodyAndForceFeedback: boolean;
  isForceFeedback: boolean

  constructor(private adviceService: AdviceService) { }


  ngOnInit() {
    this.isFeedback = null;
    this.isBody = null;
    this.isBodyAndForceFeedback = null;
    this.isBodyAndVibration = null;
    this.isForceFeedback = null;
    this.isVibration = null;

  }

  change3II1() {

  }
  change3II11() {
    this.adviceService.result_senses.haptisch = true;
    this.adviceService.result_quality.haptisch.schematisch = false;
    this.adviceService.nextStep(11);
  }

  change3II12() {
    this.adviceService.result_quality.haptisch.abstrakt = false;
    this.adviceService.result_quality.haptisch.einfach = false;
    this.adviceService.result_quality.haptisch.real = false;
    this.adviceService.result_quality.haptisch.realitaetsnah = false;
    this.adviceService.result_senses.haptisch = this.adviceService.result_quality.haptisch.schematisch;

    //Display
    this.adviceService.result_display.haptisch.setAll(false);
    //Bei Klick nur handhelddevices so lala gegeben deshalb alle aus und die zwei an schalten
    this.adviceService.result_display.haptisch.hhd = this.adviceService.result_quality.haptisch.schematisch;
    this.adviceService.result_display.haptisch.obd = this.adviceService.result_quality.haptisch.schematisch;
    this.adviceService.nextStep(11);
  }

  change3II111() {
    this.adviceService.result_quality.haptisch.abstrakt = this.isBodyAndVibration;
    this.adviceService.result_quality.haptisch.einfach = !this.isBodyAndVibration;
    this.adviceService.result_quality.haptisch.schematisch = !this.isBodyAndVibration;
    this.adviceService.result_quality.haptisch.real = !this.isBodyAndVibration;
    this.adviceService.result_quality.haptisch.realitaetsnah = !this.isBodyAndVibration;

    //Display
    this.adviceService.result_display.haptisch.setAll(false);
    this.adviceService.result_display.haptisch.obd = this.isBodyAndForceFeedback;

  }

  chooseNatural() {
    this.adviceService.result_quality.haptisch.setAll(false);
    this.adviceService.result_quality.haptisch.real = true;

    this.adviceService.result_display.haptisch.setAll(false);
    this.adviceService.result_display.haptisch.natural = true;
  }
  change3II11122() {
    this.adviceService.result_quality.haptisch.setAll(false);
    this.adviceService.result_quality.haptisch.abstrakt = !this.isBodyAndForceFeedback;
    this.adviceService.result_quality.haptisch.realitaetsnah = this.isBodyAndForceFeedback;

    //Display
    this.adviceService.result_display.haptisch.setAll(false);
    this.adviceService.result_display.haptisch.obd = false;
  }
  change3II112() {
    this.adviceService.result_quality.haptisch.setAll(false);
    this.adviceService.result_quality.haptisch.einfach = this.isVibration;

    //Display
    this.adviceService.result_display.haptisch.setAll(false);
    this.adviceService.result_display.haptisch.hhd = this.isVibration;
  }
  change3II1122() {
    this.adviceService.result_quality.haptisch.setAll(false);
    this.adviceService.result_quality.haptisch.realitaetsnah = this.isForceFeedback;
    this.adviceService.result_quality.haptisch.abstrakt = !this.isForceFeedback;

    //Display
    this.adviceService.result_display.haptisch.setAll(false);
    this.adviceService.result_display.haptisch.hhd = true;
    this.adviceService.result_display.haptisch.hmd = true;
    this.adviceService.result_display.haptisch.seethrough = true;
    this.adviceService.result_display.haptisch.nonseethrough = true;
  }
}
