export class MediaModel {
    oned: boolean;
    twod: boolean;
    photo: boolean;
    video: boolean;
    threed: boolean;
    degreePhoto: boolean;
    degreeVideo: boolean;
    vr: boolean;
    av: boolean;
    arStatic: boolean;
    ar: boolean;
    mr: boolean;
    constructor() {
        this.oned = true;
        this.twod = true;
        this.photo = true;
        this.video = true;
        this.threed = true;
        this.degreePhoto = true;
        this.degreeVideo = true;
        this.vr = true;
        this.av = true;
        this.arStatic = true;
        this.ar = true;
        this.mr = true;
    }
    setAll(variable: boolean) {
        this.oned = variable;
        this.twod = variable;
        this.photo = variable;
        this.video = variable;
        this.threed = variable;
        this.degreePhoto = variable;
        this.degreeVideo = variable;
        this.vr = variable;
        this.av = variable;
        this.arStatic = variable;
        this.ar = variable;
        this.mr = variable;
    }
    reset() {
        this.setAll(false);
    }
    loadDemo() {
        this.setAll(false);
        this.vr = true;
    }

    getNameOfChoosenMedia(): string {
        if (this.ar) {
            return 'AR';
        }
        if (this.av) {
            return 'AV';
        }
        if (this.vr) {
            return 'VR';
        }
        if (this.degreeVideo) {
            return '360 Grad-Video';
        }
        if (this.degreePhoto) {
            return '360 Grad-Foto';
        }
        if (this.threed) {
            return '3D';
        }
        if (this.video) {
            return 'Video';
        }
        if (this.photo) {
            return 'Foto';
        }
        if (this.twod) {
            return '2D';
        }
        if (this.arStatic) {
            return 'AR (statisch)';
        }
        if (this.oned) {
            return '1D';
        }
    }
    getNameOfWorseMedia(): string {
        if (this.ar) {
            return 'AV';
        }
        if (this.av) {
            return 'VR';
        }
        if (this.vr) {
            return '360 Grad-Video';
        }
        if (this.degreeVideo) {
            return 'Video';
        }
        if (this.degreePhoto) {
            return 'Foto';
        }
        if (this.threed) {
            return 'Video';
        }
        if (this.video) {
            return '2D';
        }
        if (this.photo) {
            return '2D';
        }
        if (this.twod) {
            return 'Nothing';
        }
        if (this.oned) {
            return 'Nothing';
        }
        if (this.arStatic) {
            return 'VR';
        }

    }
    getNameOfBetterMedia(): string {
        if (this.ar) {
            return 'Nothing';
        }
        if (this.av) {
            return 'Nothing';
        }
        if (this.vr) {
            return 'AV';
        }
        if (this.degreeVideo) {
            return 'VR';
        }
        if (this.degreePhoto) {
            return '360 Grad-Video';
        }
        if (this.threed) {
            return '360 Grad-Foto';
        }
        if (this.video) {
            return '3D';
        }
        if (this.photo) {
            return 'Video';
        }
        if (this.twod) {
            return '3D';
        }
        if (this.oned) {
            return '2D';
        }
        if (this.arStatic) {
            return 'AR';
        }
    }
    //function to switch names of programm based media to normal one --> e.g. oned to 1D
    translateMedia(variable: any): string {
        switch (variable) {
            case "oned":
                return '1D';
            case "twod":
                return '2D';
            case "threed":
                return '3D';
            case "photo":
                return 'Foto';
            case "video":
                return 'Video';
            case "degreePhoto":
                return '360 Grad-Foto';
            case "degreeVideo":
                return '360 Grad-Video';
            case "vr":
                return 'VR';
            case "ar":
                return 'AR';
            case "av":
                return 'AV';
            case "arStatic":
                return 'AR (statisch)';
            case "mr":
                return 'MR';
            default:
                return '' + variable;
        }
    }
}

export class DisplaysModel {
    visuell: DisplayModel;
    auditiv: DisplayModel;
    haptisch: DisplayModel;
    olfaktorisch: DisplayModel;
    gustatorisch: DisplayModel;
    koerpergefuehl: DisplayModel;
    bewegung: DisplayModel;
    constructor() {
        this.visuell = new DisplayModel();
        this.auditiv = new DisplayModel();
        this.haptisch = new DisplayModel();
        this.olfaktorisch = new DisplayModel();
        this.gustatorisch = new DisplayModel();
        this.koerpergefuehl = new DisplayModel();
        this.bewegung = new DisplayModel();
    }
    reset() {
        this.visuell.setAll(null);
        this.auditiv.setAll(null);
        this.haptisch.setAll(null);
        this.olfaktorisch.setAll(null);
        this.gustatorisch.setAll(null);
        this.koerpergefuehl.setAll(null);
        this.bewegung.setAll(null);
        // this.visuell.setAll(false);
        // this.auditiv.setAll(false);
        // this.haptisch.setAll(false);
        // this.olfaktorisch.setAll(false);
        // this.gustatorisch.setAll(false);
        // this.koerpergefuehl.setAll(false);
        // this.bewegung.setAll(false);
    }
    //Method to make it like it would be in VR4Teach
    loadDemo() {
        this.reset();
        this.visuell.hhd = true;
        this.visuell.hmd = true;
        this.visuell.obd = true;
        this.visuell.nonseethrough = true;

        this.auditiv.earmounted = true;
        this.auditiv.inear = true;
        this.auditiv.overear = true;

        this.haptisch.hhd = true

        this.koerpergefuehl.hhd = true;
        this.bewegung.hhd = true;

    }
}


export class DisplayModel {
    wfd: boolean;
    fishtank: boolean;
    surround: boolean;
    obd: boolean;
    hmd: boolean;
    seethrough: boolean;
    nonseethrough: boolean;
    earmounted: boolean;
    overear: boolean;
    inear: boolean;
    hhd: boolean;
    bodymounted: boolean;
    natural: boolean;

    constructor() {
        this.wfd = true;
        this.fishtank = true;
        this.surround = true;
        this.obd = true;
        this.hmd = true;
        this.seethrough = true;
        this.nonseethrough = true;
        this.earmounted = true;
        this.overear = true;
        this.inear = true;
        this.hhd = true;
        this.bodymounted = true;
        this.natural = true;
    }

    setAll(value: boolean) {
        this.wfd = value;
        this.fishtank = value;
        this.surround = value;
        this.obd = value;
        this.hmd = value;
        this.seethrough = value;
        this.nonseethrough = value;
        this.earmounted = value;
        this.overear = value;
        this.inear = value;
        this.hhd = value;
        this.bodymounted = value;
        this.natural = value;
    }
}