import { Component, OnInit, ViewChild } from '@angular/core';
import { PanserviceService } from '../services/panservice.service';
import { Panmodell } from '../models/pan.model';
import * as pdfmake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { BilanzService } from '../services/bilanz.service';
import { ImageBase64 } from '../models/imageBase64';

export interface TableElement {
  name: string;
  detail: string;
  countDetail: string;
  rating: string;
  ratingSum: string;
}
const ExampleData: TableElement[] = [
  { name: 'Immersion', detail: '', countDetail: '', rating: '', ratingSum: '60/60' },
  { name: 'Präsenz', detail: '', countDetail: '', rating: '', ratingSum: '20/20' },
  { name: 'Wechselwirkung', detail: '', countDetail: '', rating: '', ratingSum: '20/20' },
]

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})

export class ResultComponent implements OnInit {

  panmodell: Panmodell;
  result: any;
  isCalculated = false;
  comparison: String;
  imageHandler: ImageBase64;


  constructor(private panservice: PanserviceService, private bilanzservice: BilanzService) {
    this.imageHandler = new ImageBase64();
  }

  ngOnInit() {
    this.panmodell = new Panmodell('Anwendungsbeispiel', 'Ausbildung', this.panservice.pandefinitions);
    this.result = { Minium: 0, Maximum: 0, Count: 0, Average: 0 };
    this.bilanzservice.loadData();

  }
  refresh() {
    this.isCalculated = true;
    this.panmodell = this.panservice.getPanmodell();
    this.result = this.bilanzservice.getValues(this.panmodell.Category);
    this.comparison = this.createComparisonText();
    this.drawChart();
    this.drawTable();

  }

  formatDate = function (date) {
    let d = new Date(date),
      _year = d.getFullYear(),
      _month = '' + (d.getMonth() + 1),
      _day = '' + d.getDate();

    if (_month.length < 2)
      _month = '0' + _month;
    if (_day.length < 2)
      _day = '0' + _day;
    return [_day, _month, _year].join('.');
  }

  formatLabel(value: number) {
    // return value + ' PP';
    return value;
  }

  debug() {
    console.log('Result Panmodell:');
    this.panmodell = this.panservice.getPanmodell();
    console.log(this.panmodell);
    this.drawChart();
    this.drawTable();
  }

  //Create Sentence
  createComparisonText(): String {
    var txt = '';
    txt = 'Die Anwendung "' + this.panmodell.Name + '" erreicht in der Kategorie "' + this.panmodell.Category + '" ';
    //Durchschnittsbereich mit einem Zentel von Max - Min
    var areaAverage = ((this.result.Maximum - this.result.Minimum) / 10) * 2;

    //Über Durchschnitt
    if (this.panmodell.Panfactor > (this.result.Average + areaAverage)) {
      txt += 'einen überdurchschnittlichen Wert von ' + this.panmodell.Panfactor + '. ';
      //Über Maximum
      if (this.panmodell.Panfactor >= this.result.Maximum) {
        txt += 'Die Anwendung "' + this.panmodell.Name + '" liegt damit ' + (this.panmodell.Panfactor - this.result.Maximum) + ' Pan-Punkte über der bisherigen realsten Anwendung. \n';
      } else {
        txt += 'Die Anwendung "' + this.panmodell.Name + '" liegt damit im zukunftsorientieren Bereich der Ingenieuranwendungen der Digitalen Realität. \n';
      }
      //Gleich Durchschnitt
    } else if (this.panmodell.Panfactor <= (this.result.Average + areaAverage) && this.panmodell.Panfactor >= (this.result.Average - areaAverage)) {
      txt += 'einen durchschnittlichen Wert von ' + this.panmodell.Panfactor + '. ';
      txt += 'Die Anwendung "' + this.panmodell.Name + '" liegt damit im Bereich der meisten Ingenieuranwendungen der Digitalen Realität. \n';
    } else {
      //Unter Durchschnitt
      txt += 'einen Wert von ' + this.panmodell.Panfactor + '. ';
      //Unter Minimum
      if (this.panmodell.Panfactor < this.result.Minimum) {
        txt += 'Die Anwendung "' + this.panmodell.Name + '" liegt damit ' + (this.result.Minimum - this.panmodell.Panfactor) + ' Pan-Punkte unter dem Minimum bisheriger Ingenieuranwendungen im Bereich der Digitalen Realität. \n';
      } else {
        txt += 'Die Anwendung "' + this.panmodell.Name + '" liegt damit im preisgünstigen Bereich der Ingenieuranwendungen der Digitalen Realität. \n';
      }
    }
    txt += '\n';
    txt += 'Die Graphik zeigt den Anteil an Immersion (' + this.panmodell.Immersion.Rating + ' Pan-Punkte) in lila, in gelb die Präsenz (' + this.panmodell.Presense.Rating + ' Pan-Punkte) und den Anteil der Wechselwirkung (' + this.panmodell.Interactivity.Rating + ' Pan-Punkte) in blau an.';
    txt += '\n\n Daneben kann die Anwendung mit anderen der Digitalen Realität im gleichen Bereich verglichen werden. ';
    txt += 'Es sind ' + this.result.Count + ' Anwendungen im Bereich "' + this.panmodell.Category + '" hinterlegt. Hier beträgt der durchschnittliche Pan-Faktor ' + this.result.Average + ' Pan-Punkte. ';
    txt += 'Das Maximum liegt bei ' + this.result.Maximum + ' Pan-Punkten und das Minimum bei ' + this.result.Minimum + ' Pan-Punkten (Gestrichelte grüne Linien).'

    return txt;
  }

  //#region ------- PDF-Bericht -------

  printReport() {
    this.exportPNG();

    pdfmake.vfs = pdfFonts.pdfMake.vfs;
    var docDef = {
      pageSize: 'A4',
      watermark: { text: 'Demonstrator (www.pan-factor.info)', angle: 45, opacity: 0.1, bold: true, italics: false },
      //[left, top, right, bottom] in inches to convert: cm*0,3937
      pageMargins: [40, 60, 40, 60],
      content: [
        {
          image: this.imageHandler.getLogoPanFaktor(),
          fit: [500, 500],
          style: 'figure'
        },
        { text: ' ', style: 'text' },
        { text: ' ', style: 'text' },
        { text: ' ', style: 'text' },
        { text: ' ', style: 'text' },
        { text: 'Ermittlung des Pan-Faktors für Ihre Anwendung (nicht zertifiziert)', style: 'header' },
        { text: ' ', style: 'text' },
        { text: 'Der Pan-Faktor dient der Bewertung von Anwendungen der digitalen Realität z.B. (2D, 3D, 360°, AR, VR, XR)-Anwendungen und umfasst eine Skala von 0 – 100 Pan-Punkten. Hierbei ist ein Wert 0 eine absolut realitätsferne Anwendung. Der Wert 100 entspricht der natürlichen Realität sprich unserer Umgebung mit allen Sinneswahrnehmungen, Bewegungsfreiheiten, Körpergefühl und Interaktionsmöglichkeiten.', style: 'text' },
        { text: ' ', style: 'text', pageBreak: 'after' },
        { text: this.panmodell.Name, style: 'header' },
        { text: ' ', style: 'text' },
        { text: '> Pan-Faktor: ' + this.panmodell.Panfactor + ' von 100 <', style: 'highlighted' },
        { text: ' ', style: 'text' },
        { text: this.comparison, style: 'text' },
        { text: ' ', style: 'text' },
        {
          image: this.chart.getImageURI(),
          fit: [700, 400],
          style: 'figure'
        },
        { text: 'Abbildung: Pan-Faktor von ' + this.panmodell.Name + ' im Vergleich zu anderen Anwendungen', style: 'figureText' },
        { text: 'Berechnungsdetails:', pageBreak: 'before' },
        { text: '', style: 'text' },
        { text: '', style: 'text' },
        {
          layout: 'lightHorizontalLines',//'headerLineOnly', // optional
          table: {
            headerRows: 1,
            widths: ['*', 'auto', '*', '*', '*'],
            body: [
              //Green #7fab16
              //Blue #164C75
              //Purple #951164
              //Red #B11722
              ['Bezeichnung', 'Detail', 'Faktor', 'Bewertung', 'Summe'],
              [
                { text: 'Pan-Faktor', style: 'tablePan' },
                { text: '', style: 'tablePan' },
                { text: '', style: 'tablePan' },
                { text: '', style: 'tablePan' },
                { text: '' + this.panmodell.Panfactor + '/100', style: 'tablePan' }
              ],
              [
                { text: 'Immersion', style: 'tableImmersion' },
                { text: '', style: 'tableImmersion' },
                { text: '', style: 'tableImmersion' },
                { text: '', style: 'tableImmersion' },
                { text: '' + this.panmodell.Immersion.Rating + '/60', style: 'tableImmersion' }
              ],
              [
                { text: 'Anzahl Sinne', style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' },
                { text: '' + this.panmodell.Immersion.CountOfSense, style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: '', style: 'default' },
                { text: 'Anzahl', style: 'default' },
                { text: '' + this.panmodell.Immersion.CountOfSense / 2, style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: 'Visuell', style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' },
                { text: '' + this.panmodell.Immersion.Visually.getSum(), style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: '', style: 'default' },
                { text: 'Qualität', style: 'default' },
                { text: '' + this.panmodell.Immersion.Visually.getQualityNumber(), style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: '', style: 'default' },
                { text: 'Quantität', style: 'default' },
                { text: '' + this.panmodell.Immersion.Visually.getQuantityNumber(), style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: 'Auditiv', style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' },
                { text: '' + this.panmodell.Immersion.Aurally.getSum(), style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: '', style: 'default' },
                { text: 'Qualität', style: 'default' },
                { text: '' + this.panmodell.Immersion.Aurally.getQualityNumber(), style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: '', style: 'default' },
                { text: 'Quantität', style: 'default' },
                { text: '' + this.panmodell.Immersion.Aurally.getQuantityNumber(), style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: 'Haptisch', style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' },
                { text: '' + this.panmodell.Immersion.Haptic.getSum(), style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: '', style: 'default' },
                { text: 'Qualität', style: 'default' },
                { text: '' + this.panmodell.Immersion.Haptic.getQualityNumber(), style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: '', style: 'default' },
                { text: 'Quantität', style: 'default' },
                { text: '' + this.panmodell.Immersion.Haptic.getQuantityNumber(), style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: 'Olfaktorisch', style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' },
                { text: '' + this.panmodell.Immersion.Olfactory.getSum(), style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: '', style: 'default' },
                { text: 'Qualität', style: 'default' },
                { text: '' + this.panmodell.Immersion.Olfactory.getQualityNumber(), style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: '', style: 'default' },
                { text: 'Quantität', style: 'default' },
                { text: '' + this.panmodell.Immersion.Olfactory.getQuantityNumber(), style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: 'Gustatorisch', style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' },
                { text: '' + this.panmodell.Immersion.Gustatory.getSum(), style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: '', style: 'default' },
                { text: 'Qualität', style: 'default' },
                { text: '' + this.panmodell.Immersion.Gustatory.getQualityNumber(), style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: '', style: 'default' },
                { text: 'Quantität', style: 'default' },
                { text: '' + this.panmodell.Immersion.Gustatory.getQuantityNumber(), style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: 'Präsenz', style: 'tablePresence' },
                { text: '', style: 'tablePresence' },
                { text: '', style: 'tablePresence' },
                { text: '', style: 'tablePresence' },
                { text: '' + this.panmodell.Presense.Rating + '/20', style: 'tablePresence' }
              ],
              [
                { text: 'Körpergefühl', style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' },
                { text: '' + this.panmodell.Presense.Bodyawarness.getSum(), style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: '', style: 'default' },
                { text: 'Qualität', style: 'default' },
                { text: '' + this.panmodell.Presense.Bodyawarness.getQualityNumber(), style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: '', style: 'default' },
                { text: 'Quantität', style: 'default' },
                { text: '' + this.panmodell.Presense.Bodyawarness.getQuantityNumber(), style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: 'Bewegung', style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' },
                { text: '' + this.panmodell.Presense.Movement.getSum(), style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: '', style: 'default' },
                { text: 'Qualität', style: 'default' },
                { text: '' + this.panmodell.Presense.Movement.getQualityNumber(), style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: '', style: 'default' },
                { text: 'Quantität', style: 'default' },
                { text: '' + this.panmodell.Presense.Movement.getQuantityNumber(), style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: 'Wechselwirkung', style: 'tableInteractivity' },
                { text: '', style: 'tableInteractivity' },
                { text: '', style: 'tableInteractivity' },
                { text: '', style: 'tableInteractivity' },
                { text: '' + this.panmodell.Interactivity.Rating + '/20', style: 'tableInteractivity' }
              ],
              [
                { text: 'Interaktion', style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' },
                { text: '' + this.panmodell.Interactivity.Interaction.getSum(), style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: '', style: 'default' },
                { text: 'Qualität', style: 'default' },
                { text: '' + this.panmodell.Interactivity.Interaction.getQualityNumber(), style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: '', style: 'default' },
                { text: 'Quantität', style: 'default' },
                { text: '' + this.panmodell.Interactivity.Interaction.getQuantityNumber(), style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: 'Kommunikation', style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' },
                { text: '' + this.panmodell.Interactivity.Communication.getSum(), style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: '', style: 'default' },
                { text: 'Qualität', style: 'default' },
                { text: '' + this.panmodell.Interactivity.Communication.getQualityNumber(), style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' }
              ],
              [
                { text: '', style: 'default' },
                { text: 'Quantität', style: 'default' },
                { text: '' + this.panmodell.Interactivity.Communication.getQuantityNumber(), style: 'default' },
                { text: '', style: 'default' },
                { text: '', style: 'default' }
              ]
            ]
          }
        },

        { text: ' ', style: 'text' },
        { text: 'Diese demonstrative Berechnung wurde vom Pan-Meter durchgeführt und maschinell am ' + this.formatDate(Date.now()) + ' erstellt.', style: 'note' },
        { text: 'Entwickelt von Christian Eller 2019-2020 - Institut für Numerische Methoden und Informatik im Bauwesen; TU Darmstadt;', link: 'https://www.iib.tu-darmstadt.de', style: 'note' },
        { text: 'Es handelt sich hier um einen nicht zertifizierten Pan-Faktor. Mehr Informationen auf www.pan-factor.info', link: 'https://www.pan-factor.info', style: 'note' },
      ],

      header: {
        margin: [40, 30, 40, 20],
        columns: [
          {
            text: 'Pan-Faktor für ' + this.panmodell.Name,
            width: '*',
            alignment: 'left',
            fontSize: 12,
            bold: true
            // style: 'header2',
          },
          {
            image: this.imageHandler.getLogoPanFaktor(),
            width: 40,
            margin: [0, -15, -20, 0],
          }
        ]

      },
      footer: function (currentPage, pageCount) {
        return [
          { text: currentPage.toString() + ' von ' + pageCount, style: 'footer' }
        ];
      },
      info: {
        title: 'Pan-Faktor für ' + this.panmodell.Name,
        author: 'Christian Eller M.Sc.',
        creator: 'Christian Eller M.Sc.',
        producer: 'Christian Eller M.Sc.',
        keywords: 'Pan-Faktor", "Digitale Realität", "' + this.panmodell.Name
      },
      styles: {
        figureText: {
          alignment: 'center',
          fontSize: 10,
        },
        figure: {
          alignment: 'center'
        },
        header2: {
          fontSize: 11,
          alignment: 'center',
          // margin: [0, 40, 0, 0]
        },
        footer: {
          fontSize: 11,
          alignment: 'center',
          margin: [20, 20, 20, 0]
        },
        note: {
          fontSize: 9,
          alignment: 'left',
          margin: [20, 0, 20, 0]
        },
        highlighted: {
          bold: true,
          alignment: 'center',
          fontSize: 14,
          margin: [10, 0, 10, 0]
        },
        header: {
          //font: 'Helvetica',
          bold: true,
          fontSize: 14,
          alignment: 'center',
          decoration: 'underline',
          decorationColor: '#7FAB16'
        },
        text: {
          //font: 'Helvetica',
          fontSize: 12,
          alignment: 'justify',
          margin: [10, 0, 10, 0]

        },
        tablePan: {
          fillColor: '#7fab16',
          bold: true
        },
        tableImmersion: {
          fillColor: '#951164',
          color: '#ffffff',
          bold: true
        },
        tablePresence: {
          fillColor: '#d7ac00',
          bold: true
        },
        tableInteractivity: {
          fillColor: '#164C75',
          color: '#ffffff',
          bold: true
        }
      },

    }
    pdfmake.createPdf(docDef).download('Pan-Faktor_' + this.panmodell.Name + '.pdf');
  }

  //#endregion

  //#region ------------------ Tabelle --------------------------
  // displayedColumns: string[] = ['name', 'detail', 'countDetail', 'rating', 'ratingSum'];
  displayedColumns: string[] = ['name', 'rating', 'ratingSum'];
  tableData = ExampleData;


  drawTable() {
    this.tableData = [
      { name: 'Pan-Faktor', detail: '', countDetail: '', rating: '', ratingSum: '' + this.panmodell.Panfactor + '/100' },
      { name: '1. Immersion', detail: '', countDetail: '', rating: '', ratingSum: '' + this.panmodell.Immersion.Rating + '/60' },
      { name: '1.1. Bespielen der Sinne', detail: '', countDetail: '', rating: '' + this.panmodell.Immersion.CountOfSense, ratingSum: '' },
      { name: '1.2. Visuell', detail: '', countDetail: '', rating: '' + this.panmodell.Immersion.Visually.getSum(), ratingSum: '' },
      { name: '1.3. Auditiv', detail: '', countDetail: '', rating: '' + this.panmodell.Immersion.Aurally.getSum(), ratingSum: '' },
      { name: '1.4. Haptisch', detail: '', countDetail: '', rating: '' + this.panmodell.Immersion.Haptic.getSum(), ratingSum: '' },
      { name: '1.5. Olfaktorisch', detail: '', countDetail: '', rating: '' + this.panmodell.Immersion.Olfactory.getSum(), ratingSum: '' },
      { name: '1.6. Gustatorisch', detail: '', countDetail: '', rating: '' + this.panmodell.Immersion.Gustatory.getSum(), ratingSum: '' },

      { name: '2. Präsenz', detail: '', countDetail: '', rating: '', ratingSum: '' + this.panmodell.Presense.Rating + '/20' },
      { name: '2.1. Körpergefühl', detail: '', countDetail: '', rating: '' + this.panmodell.Presense.Bodyawarness.getSum(), ratingSum: '' },
      { name: '2.2. Bewegung', detail: '', countDetail: '', rating: '' + this.panmodell.Presense.Movement.getSum(), ratingSum: '' },

      { name: '3. Wechselwirkung', detail: '', countDetail: '', rating: '', ratingSum: '' + this.panmodell.Interactivity.Rating + '/20' },
      { name: '3.1. Interaktion', detail: '', countDetail: '', rating: '' + this.panmodell.Interactivity.Interaction.getSum(), ratingSum: '' },
      { name: '3.2. Kommunikation', detail: '', countDetail: '', rating: '' + this.panmodell.Interactivity.Communication.getSum(), ratingSum: '' },
    ]
  }
  drawTableDetail() {
    this.tableData = [
      { name: 'Pan-Faktor', detail: '', countDetail: '', rating: '', ratingSum: '' + this.panmodell.Panfactor + '/100' },
      { name: 'Immersion', detail: '', countDetail: '', rating: '', ratingSum: '' + this.panmodell.Immersion.Rating + '/60' },
      { name: 'Bespielen der Sinne', detail: '', countDetail: '', rating: '' + this.panmodell.Immersion.CountOfSense, ratingSum: '' },
      { name: '', detail: 'Anzahl:', countDetail: '' + (this.panmodell.Immersion.CountOfSense / 2), rating: '', ratingSum: '' },
      { name: 'Visuell', detail: '', countDetail: '', rating: '' + this.panmodell.Immersion.Visually.getSum(), ratingSum: '' },
      { name: '', detail: 'Qualität:', countDetail: '' + this.panmodell.Immersion.Visually.getQualityNumber(), rating: '', ratingSum: '' },
      { name: '', detail: 'Quanität:', countDetail: '' + this.panmodell.Immersion.Visually.getQuantityNumber(), rating: '', ratingSum: '' },
      { name: 'Auditiv', detail: '', countDetail: '', rating: '' + this.panmodell.Immersion.Aurally.getSum(), ratingSum: '' },
      { name: '', detail: 'Qualität:', countDetail: '' + this.panmodell.Immersion.Aurally.getQualityNumber(), rating: '', ratingSum: '' },
      { name: '', detail: 'Quanität:', countDetail: '' + this.panmodell.Immersion.Aurally.getQuantityNumber(), rating: '', ratingSum: '' },
      { name: 'Haptisch', detail: '', countDetail: '', rating: '' + this.panmodell.Immersion.Haptic.getSum(), ratingSum: '' },
      { name: '', detail: 'Qualität:', countDetail: '' + this.panmodell.Immersion.Haptic.getQualityNumber(), rating: '', ratingSum: '' },
      { name: '', detail: 'Quanität:', countDetail: '' + this.panmodell.Immersion.Haptic.getQuantityNumber(), rating: '', ratingSum: '' },
      { name: 'Olfaktorisch', detail: '', countDetail: '', rating: '' + this.panmodell.Immersion.Olfactory.getSum(), ratingSum: '' },
      { name: '', detail: 'Qualität:', countDetail: '' + this.panmodell.Immersion.Olfactory.getQualityNumber(), rating: '', ratingSum: '' },
      { name: '', detail: 'Quanität:', countDetail: '' + this.panmodell.Immersion.Olfactory.getQuantityNumber(), rating: '', ratingSum: '' },
      { name: 'Gustatorisch', detail: '', countDetail: '', rating: '' + this.panmodell.Immersion.Gustatory.getSum(), ratingSum: '' },
      { name: '', detail: 'Qualität:', countDetail: '' + this.panmodell.Immersion.Gustatory.getQualityNumber(), rating: '', ratingSum: '' },
      { name: '', detail: 'Quanität:', countDetail: '' + this.panmodell.Immersion.Gustatory.getQuantityNumber(), rating: '', ratingSum: '' },

      { name: 'Präsenz', detail: '', countDetail: '', rating: '', ratingSum: '' + this.panmodell.Presense.Rating + '/20' },
      { name: 'Körpergefühl', detail: '', countDetail: '', rating: '' + this.panmodell.Presense.Bodyawarness.getSum(), ratingSum: '' },
      { name: '', detail: 'Qualität:', countDetail: '' + this.panmodell.Presense.Bodyawarness.getQualityNumber(), rating: '', ratingSum: '' },
      { name: '', detail: 'Quanität:', countDetail: '' + this.panmodell.Presense.Bodyawarness.getQuantityNumber(), rating: '', ratingSum: '' },
      { name: 'Bewegung', detail: '', countDetail: '', rating: '' + this.panmodell.Presense.Movement.getSum(), ratingSum: '' },
      { name: '', detail: 'Qualität:', countDetail: '' + this.panmodell.Presense.Movement.getQualityNumber(), rating: '', ratingSum: '' },
      { name: '', detail: 'Quanität:', countDetail: '' + this.panmodell.Presense.Movement.getQuantityNumber(), rating: '', ratingSum: '' },

      { name: 'Wechselwirkung', detail: '', countDetail: '', rating: '', ratingSum: '' + this.panmodell.Interactivity.Rating + '/20' },
      { name: 'Interaktion', detail: '', countDetail: '', rating: '' + this.panmodell.Interactivity.Interaction.getSum(), ratingSum: '' },
      { name: '', detail: 'Qualität:', countDetail: '' + this.panmodell.Interactivity.Interaction.getQualityNumber(), rating: '', ratingSum: '' },
      { name: '', detail: 'Quanität:', countDetail: '' + this.panmodell.Interactivity.Interaction.getQuantityNumber(), rating: '', ratingSum: '' },
      { name: 'Kommunikation', detail: '', countDetail: '', rating: '' + this.panmodell.Interactivity.Communication.getSum(), ratingSum: '' },
      { name: '', detail: 'Qualität:', countDetail: '' + this.panmodell.Interactivity.Communication.getQualityNumber(), rating: '', ratingSum: '' },
      { name: '', detail: 'Quanität:', countDetail: '' + this.panmodell.Interactivity.Communication.getQuantityNumber(), rating: '', ratingSum: '' },
    ]
  }

  //#endregion

  //#region ------------------------------ Diagrammm -------------------------------
  chart: google.visualization.ComboChart
  exportPNG() {
    var data = google.visualization.arrayToDataTable([
      ['Pan-Punkte', 'Immersion', 'Präsenz', 'Wechselwirkung', 'Durchschnitt', 'Minimum', 'Maximum'],
      ['',
        0,
        0,
        0,
        Number(this.result.Average),
        Number(this.result.Minimum),
        Number(this.result.Maximum)
      ],
      ['Pan-Faktor',
        this.panmodell.Immersion.Rating,
        this.panmodell.Presense.Rating,
        this.panmodell.Interactivity.Rating,
        Number(this.result.Average),
        Number(this.result.Minimum),
        Number(this.result.Maximum)
      ],
      ['',
        0,
        0,
        0,
        Number(this.result.Average),
        Number(this.result.Minimum),
        Number(this.result.Maximum)
      ]
    ]
    );

    var options = {
      title: '' + this.panmodell.Name,
      colors: ['#951169', '#D7AC00', '#004E8A', '#7fab16', '#7fab16', '#7fab16'],
      titleTextStyle: {
        positon: 'center',
        fontSize: 22,
        color: '#7fab16'
      },
      vAxis: {
        title: 'Pan-Punkte',
        minValue: 0,
        maxValue: 100,
      },
      isStacked: true,
      bar: { groupWidth: '400%' },
      seriesType: 'bars',
      series: {
        3: { type: 'line', lineWidth: 3 },
        4: { type: 'line', lineWidth: 3, lineDashStyle: [4, 8], pointShape: 'triangle', pointSize: 15 },
        5: { type: 'line', lineWidth: 3, lineDashStyle: [4, 8], pointShape: { type: 'triangle', rotation: 180 }, pointSize: 15 },
      }
    };

    var chart_div = document.getElementById('chart');
    // this.chart = new google.visualization.ColumnChart(chart_div);
    this.chart = new google.visualization.ComboChart(chart_div);

    google.visualization.events.addListener(this.chart, 'ready', function () {
      // console.log('image');
      // console.log(this.chart.getImageURI());
    });

    this.chart.draw(data, options);
  }

  drawChart() {
    this.ChartTitle = '' + this.panmodell.Name;//'Pan-Faktor - ' + this.panmodell.Name;
    this.ChartData = [
      ['',
        0,
        0,
        0,
        Number(this.result.Average),
        Number(this.result.Minimum),
        Number(this.result.Maximum)
      ],
      ['Pan-Faktor',
        this.panmodell.Immersion.Rating,
        this.panmodell.Presense.Rating,
        this.panmodell.Interactivity.Rating,
        Number(this.result.Average),
        Number(this.result.Minimum),
        Number(this.result.Maximum)
      ],
      ['',
        0,
        0,
        0,
        Number(this.result.Average),
        Number(this.result.Minimum),
        Number(this.result.Maximum)
      ]
    ];
  }

  ChartTitle = 'Pan-Faktor - Absolut';
  ChartType = 'ColumnChart';
  ChartData = [
    ['Pan-Faktor', 60, 20, 20, 100]
  ];
  ChartColumnNames = [
    'Pan-Punkte', 'Immersion', 'Präsenz', 'Wechselwirkung', 'Durchschnitt', 'Minimum', 'Maximum'
  ];
  ChartWidth = 650;
  ChartHeight = 650;
  ChartOptions = {
    isStacked: true,
    legend: { position: 'top', maxLines: 4 },
    bar: { groupWidth: '400%' },
    colors: ['#951169', '#D7AC00', '#004E8A', '#7fab16', '#7fab16', '#7fab16'],
    titleTextStyle: {
      positon: 'center',
      fontSize: 22,
      color: '#7fab16'
    },
    vAxis: {
      title: 'Pan-Punkte',
      minValue: 0,
      maxValue: 100,
    },
    seriesType: 'bars',
    series: {
      3: { type: 'line', lineWidth: 3 },
      4: { type: 'line', lineWidth: 3, lineDashStyle: [4, 8], pointShape: 'triangle', pointSize: 15 },
      5: { type: 'line', lineWidth: 3, lineDashStyle: [4, 8], pointShape: { type: 'triangle', rotation: 180 }, pointSize: 15 },
    }

  }
  //#endregion
}
