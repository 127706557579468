import { Component, OnInit, ViewChild } from '@angular/core';


@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.css']
})
export class DescriptionComponent implements OnInit {
  title = "Pan-Faktor";
  type = "PieChart";
  PanPieData = [
    ['Immersion', { v: 60, f: '60 Pan-Punkte' }],
    ['Präsenz', { v: 20, f: '20 Pan-Punkte' }],
    ['Wechselwirkung', { v: 20, f: '20 Pan-Punkte' }]
  ];

  ColumnNames = [
    'Bewertungskriterien',
    'Pan-Punkte'
  ];

  options = {
    colors: ['#951169', '#D7AC00', '#004E8A'],
    fontName: 'Roboto',
    titlePosition: 'center',
    titleTextStyle: {
      fontSize: 14,
      color: '#7fab16'
    },
    legend: {
      postion: 'left',
      textstyle: {
        fontName: 'Roboto'
      }
    },
    is3D: false,
    animation: {
      duration: 1000,
      easing: 'out'
    }
  };
  width = 450;
  height = 300;

  constructor() { }

  ngOnInit() {

  }



}
