import { Component, OnInit } from '@angular/core';
import { AdviceService } from '../services/advice.service';

@Component({
  selector: 'app-prae-body',
  templateUrl: './prae-body.component.html',
  styleUrls: ['./prae-body.component.css']
})
export class PraeBodyComponent implements OnInit {

  constructor(public adviceService: AdviceService) { }

  ngOnInit() {
    this.adviceService.result_quality.koerpergefuehl.schematisch = true;
  }

  change4Ia1() {
    //Schematisch
    this.adviceService.result_quality.koerpergefuehl.einfach = !this.adviceService.result_quality.koerpergefuehl.schematisch;
    this.adviceService.result_quality.koerpergefuehl.abstrakt = !this.adviceService.result_quality.koerpergefuehl.schematisch;
    this.adviceService.result_quality.koerpergefuehl.realitaetsnah = !this.adviceService.result_quality.koerpergefuehl.schematisch;
    this.adviceService.result_quality.koerpergefuehl.real = !this.adviceService.result_quality.koerpergefuehl.schematisch;

    //Alle Displays ausschalten
    this.adviceService.result_display.koerpergefuehl.setAll(false);

    this.adviceService.nextStep(16);
  }
  change4Ia11() {
    //Einfach
    this.adviceService.result_quality.koerpergefuehl.abstrakt = !this.adviceService.result_quality.koerpergefuehl.einfach;
    this.adviceService.result_quality.koerpergefuehl.realitaetsnah = !this.adviceService.result_quality.koerpergefuehl.einfach;
    this.adviceService.result_quality.koerpergefuehl.real = !this.adviceService.result_quality.koerpergefuehl.einfach;

  }
  change4Ia111() {
    //Abstrakt
    this.adviceService.result_quality.koerpergefuehl.realitaetsnah = !this.adviceService.result_quality.koerpergefuehl.abstrakt;
    this.adviceService.result_quality.koerpergefuehl.real = !this.adviceService.result_quality.koerpergefuehl.abstrakt;

    //Display festlegen
    //Frage 4.I.b.1
    if (this.adviceService.result_quality.koerpergefuehl.abstrakt) {
      this.adviceService.result_display.koerpergefuehl.obd = false;
      this.adviceService.result_display.koerpergefuehl.hhd = true;
    } else {
      this.adviceService.result_display.koerpergefuehl.obd = true;
      this.adviceService.result_display.koerpergefuehl.hhd = false;
    }
    this.adviceService.nextStep(17);
  }

  change41a1111() {
    //Real
    this.adviceService.result_quality.koerpergefuehl.realitaetsnah = !this.adviceService.result_quality.koerpergefuehl.real;
  }
}
