import { Component, OnInit } from '@angular/core';
import { AdviceService } from '../services/advice.service';

@Component({
  selector: 'app-im-gustatorie',
  templateUrl: './im-gustatorie.component.html',
  styleUrls: ['./im-gustatorie.component.css']
})
export class ImGustatorieComponent implements OnInit {

  constructor(public adviceService: AdviceService) { }

  ngOnInit() {
    this.adviceService.result_senses.gustatorisch = false;
  }
  change3VI() {
    this.adviceService.result_quality.gustatorisch.setAll(false);
    this.adviceService.result_display.gustatorisch.setAll(false);

    //Frage 3.V.1.b1
    //Verlinkung 3.I.b.1.1.1
    if (this.adviceService.result_senses.gustatorisch) {
      this.adviceService.result_display.gustatorisch.hmd = this.adviceService.freeHands;
      this.adviceService.result_display.gustatorisch.seethrough = this.adviceService.freeHands;
      this.adviceService.result_display.gustatorisch.nonseethrough = this.adviceService.freeHands;
      this.adviceService.result_display.gustatorisch.hhd = !this.adviceService.freeHands;
    }
    this.adviceService.nextStep(15);
  }
  change3V1a1() {
    this.adviceService.nextStep(14);

  }
  change3V1a11() {

  }
  change3V1a111() {
    this.adviceService.nextStep(15);
    this.adviceService.result_quality.gustatorisch.real = !this.adviceService.result_quality.gustatorisch.abstrakt;
    this.adviceService.result_quality.gustatorisch.realitaetsnah = !this.adviceService.result_quality.gustatorisch.abstrakt;
  }

}
