import { Component, OnInit } from '@angular/core';
import { AdviceService } from '../services/advice.service';

@Component({
  selector: 'app-int-communication',
  templateUrl: './int-communication.component.html',
  styleUrls: ['./int-communication.component.css']
})
export class IntCommunicationComponent implements OnInit {

  isExisting: boolean;
  helpBoolean: boolean;
  helpBoolean1: boolean;
  helpBoolean2: boolean;
  helpBoolean3: boolean;

  constructor(private adviceService: AdviceService) { }

  ngOnInit() {
    this.adviceService.result_random.kommunikation = '';
    this.isExisting = null;
    this.helpBoolean = null;
    this.helpBoolean1 = null;
    this.helpBoolean2 = null;
    this.helpBoolean3 = null;
  }

  change5I1() {
    this.adviceService.result_quality.kommunikation.setAll(false);
    this.adviceService.result_random.kommunikation = '';
    if (this.isExisting) {
      this.adviceService.result_quality.kommunikation.schematisch = true;
    }
    this.adviceService.nextStep(20);
  }
  change5I11() {
    if (this.adviceService.result_quality.kommunikation.schematisch) {
      this.adviceService.result_random.kommunikation = 'Beobachtende Beziehung';
    } else {
      this.adviceService.result_random.kommunikation = '';
    }
  }
  change5I111() {
    if (this.helpBoolean) {
      this.adviceService.result_quality.kommunikation.einfach = false;
      this.adviceService.result_random.kommunikation = '';
    } else {
      //Einfach
      this.adviceService.result_quality.kommunikation.einfach = true;
      this.adviceService.result_random.kommunikation = 'Funktions- & Datenaustausch';
    }
  }
  change5I1111() {

  }
  change5I11111() {
    if (this.helpBoolean2) {
      this.adviceService.result_quality.kommunikation.realitaetsnah = true;
      this.adviceService.result_quality.kommunikation.abstrakt = false;
      this.adviceService.result_random.kommunikation = 'Real basierter Avatar';
    } else {
      this.adviceService.result_quality.kommunikation.realitaetsnah = false;
      this.adviceService.result_quality.kommunikation.abstrakt = true;
      this.adviceService.result_random.kommunikation = 'Avatar oder Video';
    }
  }
  change5I11112() {
    if (this.helpBoolean3) {
      this.adviceService.result_quality.kommunikation.realitaetsnah = true;
      this.adviceService.result_quality.kommunikation.einfach = false;
      this.adviceService.result_random.kommunikation = 'Avatar';
    } else {
      this.adviceService.result_quality.kommunikation.einfach = true;
      this.adviceService.result_quality.kommunikation.realitaetsnah = false;
      this.adviceService.result_random.kommunikation = 'Funktions- & Datenaustausch';
    }
  }
}
