import { Ratingmodell } from './rating.model';

export class Interactivitymodell {
    _id: String;
    Rating: number;
    Interaction: Ratingmodell;
    Communication: Ratingmodell;
    constructor(pandefinitions: any) {
        this.Rating = 0;
        this.Interaction = new Ratingmodell('Schematisch', 'Wenige', pandefinitions);
        this.Communication = new Ratingmodell('Keine', 'Keine', pandefinitions);
        this.CalculateRating();
    }
    parse(obj: any) {
        this.Rating = obj.Rating;
        this.Interaction.parse(obj.Interaction);
        this.Communication.parse(obj.Communication);
    }

    getString(advice: boolean): string {
        var txt = '';
        txt += this.Interaction.getString('Das Interaktion', 'Interaktion', advice);
        txt += this.Communication.getString('Die Kommunikation', 'Kommunikation', advice);
        return txt;
    }
    CalculateRating() {
        var rate = 0;
        if (this.Interaction.getQualityNumber() * this.Interaction.getQuantityNumber() != 0) {
            rate += this.Interaction.getQualityNumber() + this.Interaction.getQuantityNumber();
        }
        if (this.Communication.getQualityNumber() * this.Communication.getQuantityNumber() != 0) {
            rate += this.Communication.getQualityNumber() + this.Communication.getQuantityNumber();
        }
        this.Rating = rate;
        //console.log('Interaktivität berechnet: ' + this.Rating + '/20');
    }

    getBetter(step: number) {
        this.Interaction.getBetter(step);
        this.Communication.getBetter(step);
    }
}