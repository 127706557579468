import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  isGerman: boolean;
  constructor() {
    this.isGerman = true;
  }

  toogleLanguage() {
    this.isGerman = !this.isGerman;
  }

  isLanguageGerman() {
    return this.isGerman;
  }

  getOtherLanguageName(): string {
    if (this.isGerman) {
      return 'English';
    } else {
      return 'Deutsch';
    }
  }
}
