import { Component, OnInit } from '@angular/core';
import { AdviceService } from '../services/advice.service';

@Component({
  selector: 'app-prae-movement',
  templateUrl: './prae-movement.component.html',
  styleUrls: ['./prae-movement.component.css']
})
export class PraeMovementComponent implements OnInit {

  helpBoolean: boolean;
  helpBoolean1: boolean;
  helpBoolean2: boolean;

  constructor(public adviceService: AdviceService) { }

  ngOnInit() {
    this.helpBoolean = null;
    this.helpBoolean1 = null;
    this.helpBoolean2 = null;
    this.adviceService.result_random = {};
    this.adviceService.result_random.bewegung = '';
  }

  change4IIa1() {
    this.adviceService.result_quality.bewegung.einfach = !this.adviceService.result_quality.bewegung.schematisch;
    this.adviceService.result_quality.bewegung.abstrakt = !this.adviceService.result_quality.bewegung.schematisch;
    this.adviceService.result_quality.bewegung.realitaetsnah = !this.adviceService.result_quality.bewegung.schematisch;
    this.adviceService.result_quality.bewegung.real = !this.adviceService.result_quality.bewegung.schematisch;
    this.adviceService.nextStep(18);
  }

  change4IIa11() {
    this.adviceService.result_quality.bewegung.abstrakt = !this.adviceService.result_quality.bewegung.einfach;
    this.adviceService.result_quality.bewegung.realitaetsnah = !this.adviceService.result_quality.bewegung.einfach;
    this.adviceService.result_quality.bewegung.real = !this.adviceService.result_quality.bewegung.einfach;

    //Verlinkung <!-- Frage 4.II.b.1 -->
    //Display
    if (this.adviceService.result_quality.bewegung.einfach) {
      this.adviceService.result_display.bewegung.setAll(false);
      this.adviceService.result_display.bewegung.hhd = true;
      this.adviceService.result_random.bewegung = 'Traditionell';
    }
    this.adviceService.nextStep(19);
  }

  change4IIa111() {
    this.adviceService.result_quality.bewegung.realitaetsnah = !this.adviceService.result_quality.bewegung.abstrakt;
    this.adviceService.result_quality.bewegung.real = !this.adviceService.result_quality.bewegung.abstrakt;
  }

  change4IIa1111() {
    this.adviceService.result_quality.bewegung.realitaetsnah = !this.adviceService.result_quality.bewegung.real;
  }

  change4IIb11a() {
    this.adviceService.result_display.bewegung.setAll(false);
    if (this.helpBoolean) {
      this.adviceService.result_display.bewegung.hmd = true;
      this.adviceService.result_random.bewegung = 'Sensorgestützes Verfahren z.B. Leap Motion';
    } else {
      this.adviceService.result_display.bewegung.hhd = true;
    }
  }
  change4IIb11b() {
    this.change4IIb11a();
    if (this.helpBoolean1) {
      this.adviceService.result_random.bewegung = 'Teleport';
    }

  }
  change4IIb11b1() {
    if (this.helpBoolean2) {
      this.adviceService.result_display.bewegung.wfd = false;
      this.adviceService.result_display.bewegung.natural = true;
      this.adviceService.result_random.bewegung = 'Freie Bewegung';
    } else {
      this.adviceService.result_display.bewegung.wfd = true;
      this.adviceService.result_display.bewegung.natural = false;
      this.adviceService.result_random.bewegung = 'Bewegungsplattform';
    }
  }

}
