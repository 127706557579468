import { Immersionmodell } from './immersion.model';
import { Presensemodell } from './presense.model';
import { Interactivitymodell } from './interactivity.model';

export class Panmodell {
    _id: String;
    isValid: boolean;
    Name: String;
    Category: String;
    Panfactor: number;
    Immersion: Immersionmodell;
    Presense: Presensemodell;
    Interactivity: Interactivitymodell;

    constructor(name: string, category: string, pandefinitons: any) {
        //Vordefinieren des Pan-Faktors
        this.Name = name;
        this.Category = category;
        this.Panfactor = 1967;
        //Immersion
        this.Immersion = new Immersionmodell(pandefinitons);
        //Presense
        this.Presense = new Presensemodell(pandefinitons);
        //Interactivity
        this.Interactivity = new Interactivitymodell(pandefinitons);
        // this.CalculatePanFactor();

        this.isValid = false;
    }
    parse(obj: any) {
        this.Name = obj.Name;
        this.Category = obj.Category;
        this.Panfactor = obj.Panfactor;
        this.Immersion.parse(obj.Immersion);
        this.Presense.parse(obj.Presense);
        this.Interactivity.parse(obj.Interactivity);
    }

    CalculatePanFactor() {
        var rate = 0;
        //Immersion
        this.Immersion.CalculateRating();
        rate += this.Immersion.Rating;
        //Präsenz
        this.Presense.CalculateRating();
        rate += this.Presense.Rating;
        //Interaktivität
        this.Interactivity.CalculateRating();
        rate += this.Interactivity.Rating;
        //Pan-Faktor
        this.Panfactor = rate;
        console.log('Pan-Faktor berechnet: ' + this.Panfactor + '/100');
    }
    reset() {
        this.Panfactor = 0;
    }
    loadDemo() {
        this.Name = 'VR4Teach';
        this.Category = 'Ausbildung';
        this.Panfactor = 38;

    }
    getQualitytext(advice: boolean): QualityTextModel {
        return {
            immersion: this.Immersion.getString(advice),
            presence: this.Presense.getString(advice),
            interactivity: this.Interactivity.getString(advice),
        };
    }
    getBetter(step: number) {
        this.Immersion.getBetter(step);
        this.Presense.getBetter(step);
        this.Interactivity.getBetter(step);
    }


}

export class QualityTextModel {
    immersion: String;
    presence: String;
    interactivity: String;
}