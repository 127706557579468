import { Component, OnInit, ViewChild } from '@angular/core';
import { PanserviceService } from '../services/panservice.service';
import { ResultComponent } from '../result/result.component';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Panmodell } from '../models/pan.model';
import { Ratingmodell } from '../models/rating.model';

@Component({
  selector: 'app-configurator',
  templateUrl: './configurator.component.html',
  styleUrls: ['./configurator.component.css']
})
export class ConfiguratorComponent implements OnInit {
  @ViewChild('result', null)
  result: ResultComponent;
  panmodell: Panmodell;
  pandefinitions: any;
  //declare all selections
  // Step_General: FormGroup;
  // Step_Immersion: FormGroup;
  // Step_Presens: FormGroup;
  // Step_Interactivity: FormGroup;
  Step_General: 'FormGroup';
  Step_Immersion: 'FormGroup';
  Step_Presens: 'FormGroup';
  Step_Interactivity: 'FormGroup';
  selected: 'No Choice';
  selected1: 'No Choice';
  selected2: 'No Choice';
  selected3: 'No Choice';
  selected4: 'No Choice';
  selected5: 'No Choice';
  selected6: 'No Choice';
  selected7: 'No Choice';
  selected8: 'No Choice';
  selected9: 'No Choice';
  selected10: 'No Choice';
  selected11: 'No Choice';
  selected12: 'No Choice';
  selected13: 'No Choice';
  selected14: 'No Choice';
  selected15: 'No Choice';
  selected16: 'No Choice';
  selected17: 'No Choice';
  selected18: 'No Choice';


  // constructor(private panservice: PanserviceService, private _formBuilder: FormBuilder) { }
  constructor(public panservice: PanserviceService) {
    this.pandefinitions = panservice.pandefinitions;
  }

  ngOnInit() {
    this.panmodell = new Panmodell('Beispielanwendung', 'Ausbildung', this.panservice.pandefinitions);

    //Init all Selection Tabs 
    // this.Step_General = this._formBuilder.group({
    //   firstCtrl: ['']
    // });
    // this.Step_Immersion = this._formBuilder.group({
    //   secondCtrl: ['']
    // });
    // this.Step_Interactivity = this._formBuilder.group({
    //   thirdCtrl: ['']
    // });
    // this.Step_Presens = this._formBuilder.group({
    //   fourthCtrl: ['']
    // });
  }

  debug() {
    console.log('Debug was pressed.');
    console.log('--Debug Pan-Factor Modell --');
    this.panservice.setPanmodell(this.panmodell);
    console.log('Vorher:');
    console.log(this.panmodell);

    this.panmodell = this.panservice.getPanmodell();
    console.log('Nachher:');
    this.panmodell.getQualitytext(true);
    console.log(this.panmodell);

  }

  calculatePanFactor() {
    this.panmodell.CalculatePanFactor();
    this.panservice.setPanmodell(this.panmodell);
    this.result.refresh();
  }

  loadDemo() {
    this.panmodell.Name = 'VR4Teach';
    this.panmodell.Category = 'Ausbildung';

    this.panmodell.Immersion.Visually = new Ratingmodell('Realitätsnah', 'Viele', this.panservice.pandefinitions);
    this.panmodell.Immersion.Aurally = new Ratingmodell('Einfach', 'Einige', this.panservice.pandefinitions);
    this.panmodell.Immersion.Haptic = new Ratingmodell('Schematisch', 'Wenige', this.panservice.pandefinitions);
    this.panmodell.Immersion.Olfactory = new Ratingmodell('Keine', 'Keine', this.panservice.pandefinitions);
    this.panmodell.Immersion.Gustatory = new Ratingmodell('Keine', 'Keine', this.panservice.pandefinitions);

    this.panmodell.Presense.Bodyawarness = new Ratingmodell('Abstrakt', 'Wenige', this.panservice.pandefinitions);
    this.panmodell.Presense.Movement = new Ratingmodell('Einfach', 'Wenige', this.panservice.pandefinitions);

    this.panmodell.Interactivity.Interaction = new Ratingmodell('Einfach', 'Viele', this.panservice.pandefinitions);
    this.panmodell.Interactivity.Communication = new Ratingmodell('Einfach', 'Vollständig', this.panservice.pandefinitions);
  }

  refresh() {
    this.panmodell = new Panmodell('Neue Anwendung', 'Ausbildung', this.panservice.pandefinitions);
  }
}
