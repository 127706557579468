import { Component, OnInit } from '@angular/core';
import { AdviceService } from '../services/advice.service';

@Component({
  selector: 'app-qtn-general',
  templateUrl: './qtn-general.component.html',
  styleUrls: ['./qtn-general.component.css']
})
export class QtnGeneralComponent implements OnInit {

  step_General: 'FormGroup';

  constructor(public adviceService: AdviceService) { }

  ngOnInit() {

  }

  setUseHour() {
    this.adviceService.oneHourUse = this.adviceService.result_display.visuell.hmd;
    this.adviceService.result_display.visuell.seethrough = this.adviceService.result_display.visuell.hmd;
    this.adviceService.result_display.visuell.nonseethrough = this.adviceService.result_display.visuell.hmd;
    this.adviceService.nextStep(2);
  }
}
