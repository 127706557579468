import { Injectable } from '@angular/core';
import { BilanzService } from './bilanz.service';
import { HttpClient } from '@angular/common/http';
import * as parser from 'papaparse';
import * as pdfmake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

import { ImageBase64 } from '../models/imageBase64';

import { SenseQualityModel, SensesModel } from '../models/help.quality.model';
import { VarianteModel, TooltipModel } from '../models/help.variante.model';
import { MediaModel, DisplaysModel, DisplayModel } from '../models/help.media.model';
import { PanserviceService } from './panservice.service';
import { Ratingmodell } from '../models/rating.model';

@Injectable({
  providedIn: 'root'
})
export class AdviceService {

  imageHandler: ImageBase64;

  name: string;
  csvMediaAdvice: any;
  pandefinitions: any;


  //alle ergebnislisten
  result_media: MediaModel;
  result_quality: SenseQualityModel;
  result_display: DisplaysModel;
  result_random: any;
  result_compare: any;
  result_senses: SensesModel;

  category: string;
  oneHourUse: boolean;
  freeHands: boolean;
  complete: any;

  option: number;
  advice: string;
  variante1: VarianteModel;
  variante2: VarianteModel;
  variante3: VarianteModel;

  // Initalize with default params
  constructor(private bilanzService: BilanzService, private panService: PanserviceService, private http: HttpClient) {
    this.imageHandler = new ImageBase64();
    this.option = 3;
    this.oneHourUse = null;
    this.freeHands = true;
    this.complete = { finish: false, count: 1, steps: 0 };
    //Daten laden
    this.bilanzService.loadData();
    this.loadCsvData();

    //standardwerte belegen
    this.name = 'Beispielanwendung';
    this.category = 'Ausbildung';

    this.variante1 = new VarianteModel(this.panService.pandefinitions);
    this.variante2 = new VarianteModel(this.panService.pandefinitions);
    this.variante3 = new VarianteModel(this.panService.pandefinitions);

    this.result_senses = new SensesModel();
    this.result_quality = new SenseQualityModel();
    this.result_media = new MediaModel();
    this.result_display = new DisplaysModel();
    console.info('Load all data into AdviceService.');
  }


  //#region CREATE ADVICE
  calculateAdvice() {
    //vorher alle clearen
    this.variante1.typ.clear();
    this.variante2.typ.clear();
    this.variante3.typ.clear();

    //get min, max, average of applications of the same category --> "Ergebnismenge"
    this.result_compare = this.bilanzService.getValues(this.category);

    //Set Text of Adivce Text depending on the "Ergebnismenge"
    this.advice = 'In der Kategorie "' + this.category + '" sind zum Vergleich ' + this.result_compare.Count + ' Anwendungen vorhanden. Der durchschnittliche Pan-Faktor beträgt ' + this.result_compare.Average + ' Pan-Punkte. ';
    this.advice += 'Dabei erreicht die beste Anwendung ' + this.result_compare.Maximum + ' Pan-Punkte. \n';

    //Find in result_media ideal or existing value
    // console.log('Result Media');
    // console.log(this.result_media);
    // console.log('Choosen Media: ' + this.result_media.getNameOfChoosenMedia());

    var mediaAdvice = this.getAdviceByMedia(this.result_media.getNameOfChoosenMedia());
    var mediaWorseAdvice = this.getAdviceByMedia(this.result_media.getNameOfWorseMedia());
    var mediaBetterAdvice = this.getAdviceByMedia(this.result_media.getNameOfBetterMedia());

    // console.log('Try to get an advice for use-case ' + this.option);
    // console.log('This is row of mediaadvice which was choosen.');
    // console.log(mediaAdvice);

    //Set all models to this name and category
    this.variante1.panmodell.Name = this.name;
    this.variante1.minPanmodell.Name = this.name;
    this.variante2.panmodell.Name = this.name;
    this.variante2.minPanmodell.Name = this.name;
    this.variante3.panmodell.Name = this.name;
    this.variante3.minPanmodell.Name = this.name;
    this.variante1.panmodell.Category = this.category;
    this.variante1.minPanmodell.Category = this.category;
    this.variante2.panmodell.Category = this.category;
    this.variante2.minPanmodell.Category = this.category;
    this.variante3.panmodell.Category = this.category;
    this.variante3.minPanmodell.Category = this.category;

    //Advice depends on choosen option
    switch (this.option) {
      case 3:
        //Long Version --> Know Variante2 Medium, Display and Qualities

        //STEP 01: Read Media for all Variants
        //Text
        this.variante1.media.text = mediaAdvice.Preisguenstiger;
        this.variante2.media.text = mediaAdvice.Optimum;
        this.variante3.media.text = mediaAdvice.Zukunfstorientiert;

        //Tooltips
        this.variante1.media.tooltip = mediaWorseAdvice.Erklaerung;
        this.variante2.media.tooltip = mediaAdvice.Erklaerung;
        this.variante3.media.tooltip = mediaBetterAdvice.Erklaerung;

        //STEP 02: Read in all Display
        //Visuelle Displays
        this.variante2.typ.visuell = this.displayToText(this.result_display.visuell);
        //Auditive Displays
        this.variante2.typ.auditiv = this.displayToText(this.result_display.auditiv);
        //Haptische Displays
        this.variante2.typ.haptisch = this.displayToText(this.result_display.haptisch);
        //Olfaktorische Displays
        this.variante2.typ.olfaktorisch = this.displayToText(this.result_display.olfaktorisch);
        //Gustatorische Displays
        this.variante2.typ.gustatorisch = this.displayToText(this.result_display.gustatorisch);
        //Körpergefühl Displays
        this.variante2.typ.koerpergefuehl = this.displayToText(this.result_display.koerpergefuehl);
        //Bewegung Displays
        this.variante2.typ.bewegung = this.displayToText(this.result_display.bewegung);

        //STEP 03: Calculate Pan-Factor which is possible in optimum
        //Set all Qualities
        //Maximum
        this.variante2.panmodell.Immersion.Visually = this.readQualities(this.result_quality.visuell, 'Vollständig');
        this.variante2.panmodell.Immersion.Aurally = this.readQualities(this.result_quality.auditiv, 'Vollständig');
        this.variante2.panmodell.Immersion.Haptic = this.readQualities(this.result_quality.haptisch, 'Vollständig');
        this.variante2.panmodell.Immersion.Olfactory = this.readQualities(this.result_quality.olfaktorisch, 'Vollständig');
        this.variante2.panmodell.Immersion.Gustatory = this.readQualities(this.result_quality.gustatorisch, 'Vollständig');
        this.variante2.panmodell.Presense.Bodyawarness = this.readQualities(this.result_quality.koerpergefuehl, 'Vollständig');
        this.variante2.panmodell.Presense.Movement = this.readQualities(this.result_quality.bewegung, 'Vollständig');
        this.variante2.panmodell.Interactivity.Interaction = this.readQualities(this.result_quality.interaktion, 'Vollständig');
        this.variante2.panmodell.Interactivity.Communication = this.readQualities(this.result_quality.kommunikation, 'Vollständig');

        //Minimum
        this.variante2.minPanmodell.Immersion.Visually = this.readQualities(this.result_quality.visuell, 'Wenige');
        this.variante2.minPanmodell.Immersion.Aurally = this.readQualities(this.result_quality.auditiv, 'Wenige');
        this.variante2.minPanmodell.Immersion.Haptic = this.readQualities(this.result_quality.haptisch, 'Wenige');
        this.variante2.minPanmodell.Immersion.Olfactory = this.readQualities(this.result_quality.olfaktorisch, 'Wenige');
        this.variante2.minPanmodell.Immersion.Gustatory = this.readQualities(this.result_quality.gustatorisch, 'Wenige');
        this.variante2.minPanmodell.Presense.Bodyawarness = this.readQualities(this.result_quality.koerpergefuehl, 'Wenige');
        this.variante2.minPanmodell.Presense.Movement = this.readQualities(this.result_quality.bewegung, 'Wenige');
        this.variante2.minPanmodell.Interactivity.Interaction = this.readQualities(this.result_quality.interaktion, 'Wenige');
        this.variante2.minPanmodell.Interactivity.Communication = this.readQualities(this.result_quality.kommunikation, 'Wenige');

        this.variante2.panmodell.CalculatePanFactor();
        this.variante2.minPanmodell.CalculatePanFactor();

        //STEP 04: Pan-Factor all other variants depending on Media
        this.variante1.minPanmodell.Panfactor = mediaWorseAdvice.Min;
        this.variante1.averagePanfactor = mediaWorseAdvice.Durchschnitt;
        this.variante1.panmodell.isValid = true;
        if (this.variante2.panmodell.Panfactor < mediaWorseAdvice.PanFaktor) {
          this.variante1.panmodell.Panfactor = mediaWorseAdvice.Min;
        } else {
          this.variante1.panmodell.Panfactor = mediaWorseAdvice.PanFaktor;
        }

        this.variante2.averagePanfactor = mediaAdvice.Durchschnitt;
        this.variante2.panmodell.isValid = true;

        this.variante3.panmodell.Panfactor = mediaBetterAdvice.PanFaktor;
        this.variante3.minPanmodell.Panfactor = mediaBetterAdvice.Min;
        this.variante3.averagePanfactor = mediaBetterAdvice.Durchschnitt;
        this.variante3.panmodell.isValid = true;

        if (this.variante2.panmodell.Panfactor > mediaBetterAdvice.PanFaktor) {
          this.variante3.panmodell.Panfactor = mediaBetterAdvice.Max;
        } else if (this.variante2.panmodell.Panfactor < mediaBetterAdvice.Durchschnitt) {
          this.variante3.panmodell.Panfactor = mediaBetterAdvice.Durchschnitt;
        } else {
          this.variante3.panmodell.Panfactor = mediaBetterAdvice.PanFaktor;
        }
        break;
      case 2:
        //Short Version --> Know Variante2 Medium

        //STEP 01: Read Media for all Variants
        //Text
        this.variante1.media.text = mediaAdvice.Preisguenstiger;
        this.variante2.media.text = mediaAdvice.Optimum;
        this.variante3.media.text = mediaAdvice.Zukunftsorientiert;

        //Tooltips
        this.variante1.media.tooltip = mediaWorseAdvice.Erklaerung;
        this.variante2.media.tooltip = mediaAdvice.Erklaerung;
        this.variante3.media.tooltip = mediaBetterAdvice.Erklaerung;

        //STEP 02: Get Pan-Factor by Media
        //Pan-Factor depending on Media
        this.variante1.panmodell.Panfactor = mediaWorseAdvice.PanFaktor;
        this.variante1.minPanmodell.Panfactor = mediaWorseAdvice.Min;
        this.variante1.averagePanfactor = mediaWorseAdvice.Durchschnitt;
        if (mediaWorseAdvice.PanFaktor != 0) {
          this.variante1.panmodell.isValid = true;
        }
        if (mediaAdvice.Durchschnitt < mediaWorseAdvice.Min) {
          this.variante1.panmodell.Panfactor = mediaWorseAdvice.Durchschnitt;
        }


        this.variante2.panmodell.Panfactor = mediaAdvice.Durchschnitt;
        if (mediaAdvice.Durchschnitt < mediaWorseAdvice.PanFaktor) {
          this.variante2.panmodell.Panfactor = mediaAdvice.Max;
        }
        this.variante2.minPanmodell.Panfactor = mediaAdvice.Min;
        this.variante2.averagePanfactor = mediaAdvice.PanFaktor;
        this.variante2.panmodell.isValid = true;

        if (this.variante1.panmodell.Panfactor >= this.variante2.panmodell.Panfactor) {
          if (mediaWorseAdvice.Durchschnitt >= this.variante2.panmodell.Panfactor) {
            this.variante1.panmodell.Panfactor = mediaWorseAdvice.Min;
          } else {
            this.variante1.panmodell.Panfactor = mediaWorseAdvice.Durchschnitt;
          }
        }

        this.variante3.panmodell.Panfactor = mediaBetterAdvice.PanFaktor;
        if (this.variante2.panmodell.Panfactor > this.variante3.panmodell.Panfactor) {
          this.variante3.panmodell.Panfactor = mediaBetterAdvice.Max;
        }
        this.variante3.minPanmodell.Panfactor = mediaBetterAdvice.Min;
        this.variante3.averagePanfactor = mediaBetterAdvice.Durchschnitt;
        this.variante3.panmodell.isValid = true;


        break;
      case 1:
        //Existing Application --> Know Pan-Factor, Qualities and Medium

        //STEP 01: Read Media for Variant 2
        this.variante1.panmodell.isValid = false;
        this.variante2.media.text = mediaAdvice.Optimum;
        this.variante2.media.tooltip = mediaAdvice.Erklaerung;

        var panmodell = this.panService.getPanmodell();

        this.variante2.averagePanfactor = mediaAdvice.Durchschnitt;
        this.variante2.panmodell.isValid = true;

        //STEP 03: Check if Pan-Factor is good or not in comparison to other applications
        this.variante1.panmodell.Panfactor = 0;
        this.variante1.minPanmodell.Panfactor = 0;
        // console.log('Set Variant 1 to 0 = ' + this.variante1.panmodell.Panfactor);

        if (panmodell.Panfactor < mediaAdvice.Durchschnitt) {
          console.log('Pan-Faktor kleiner als Durchschnitt aller anderen Anwendungen');

          //Raise Quality and Quantity to next level
          this.variante2.panmodell.Immersion.Visually = panmodell.Immersion.Visually.getBetter(1);
          this.variante2.panmodell.Immersion.Aurally = panmodell.Immersion.Aurally.getBetter(1);
          this.variante2.panmodell.Immersion.Haptic = panmodell.Immersion.Haptic.getBetter(1);
          this.variante2.panmodell.Immersion.Olfactory = panmodell.Immersion.Olfactory.getBetter(1);
          this.variante2.panmodell.Immersion.Gustatory = panmodell.Immersion.Gustatory.getBetter(1);

          this.variante2.panmodell.Presense.Bodyawarness = panmodell.Presense.Bodyawarness.getBetter(1);
          this.variante2.panmodell.Presense.Movement = panmodell.Presense.Movement.getBetter(1);

          this.variante2.panmodell.Interactivity.Interaction = panmodell.Interactivity.Interaction.getBetter(1);
          this.variante2.panmodell.Interactivity.Communication = panmodell.Interactivity.Communication.getBetter(1);

          this.variante2.panmodell.Panfactor = mediaAdvice.Durchschnitt;
          this.variante2.minPanmodell = panmodell;

          //Raise Quality and Quantity to next level
          this.variante3.panmodell.Immersion.Visually = this.variante2.panmodell.Immersion.Visually.getBetter(1);

          this.variante3.panmodell.Immersion.Aurally = this.variante2.panmodell.Immersion.Aurally.getBetter(1);
          this.variante3.panmodell.Immersion.Haptic = this.variante2.panmodell.Immersion.Haptic.getBetter(1);
          this.variante3.panmodell.Immersion.Olfactory = this.variante2.panmodell.Immersion.Olfactory.getBetter(1);
          this.variante3.panmodell.Immersion.Gustatory = this.variante2.panmodell.Immersion.Gustatory.getBetter(1);

          this.variante3.panmodell.Presense.Bodyawarness = this.variante2.panmodell.Presense.Bodyawarness.getBetter(1);
          this.variante3.panmodell.Presense.Movement = this.variante2.panmodell.Presense.Movement.getBetter(1);

          this.variante3.panmodell.Interactivity.Interaction = this.variante2.panmodell.Interactivity.Interaction.getBetter(1);
          this.variante3.panmodell.Interactivity.Communication = this.variante2.panmodell.Interactivity.Communication.getBetter(1);

          this.variante3.panmodell.Panfactor = mediaAdvice.Max;
          this.variante3.minPanmodell = panmodell;
          this.variante3.panmodell.isValid = true;

          this.variante3.media.text = mediaAdvice.Optimum;
          this.variante3.media.tooltip = mediaAdvice.Erklaerung;


        } else if (panmodell.Panfactor >= mediaAdvice.Durchschnitt && panmodell.Panfactor < mediaAdvice.Max) {
          console.log('Pan-Faktor kleiner als Maximum aber größer als Durchschnitt aller anderen Anwendungen');

          this.variante2.panmodell = panmodell;
          this.variante2.panmodell.getBetter(1); //Raise Quality and Quantity to next level
          this.variante2.panmodell.Panfactor = mediaAdvice.Max;
          this.variante2.minPanmodell = panmodell;

          this.variante3.panmodell.isValid = false;
          this.variante1.panmodell.isValid = false;
          this.variante3.panmodell.Panfactor = 0;
          this.variante1.panmodell.Panfactor = 0;

        } else if (panmodell.Panfactor > mediaAdvice.Max) {
          console.log('Pan-Faktor größer als Maximum aller anderen Anwendungen');
          this.advice += ' Ihre Anwendung ist bereits besser als die bisherigen Anwendungen. Eine Empfehlung kann deshalb nicht erstellt werden. Diese Anwendung hebt den Bereich auf ein neues Niveau.'
        }
        break;
    }

    //Formulierung von Texten basierend auf den ermittelten panfaktoren
    var aver = this.result_compare.Average;
    var max = this.result_compare.Maximum;

    this.variante1.pantext = this.getPanText(this.variante1.panmodell.Panfactor, aver, max);
    this.variante2.pantext = this.getPanText(this.variante2.panmodell.Panfactor, aver, max);
    this.variante3.pantext = this.getPanText(this.variante3.panmodell.Panfactor, aver, max);

    //MUSIC: Lyle Workman - WonderSuite
    console.info('CREATE HELP');

    console.log('Variante 1');
    console.log(this.variante1);

    console.log('Variante 2');
    console.log(this.variante2);

    console.log('Variante 3');
    console.log(this.variante3);
    // this.debugAdvice();
  }

  //#endregion

  //#region TRANSLATE NUMBERS TO TEXT

  //function to read all displays to an normal name and tooltip
  displayToText(display: DisplayModel): TooltipModel[] {
    var keys = Object.keys(display);
    var values = Object.values(display);
    var resultModel = [];
    for (let i = 0; i < keys.length; i++) {
      const e = keys[i];
      const v = values[i];
      if (v) {
        resultModel.push(new TooltipModel().newTooltipModel(this.translateDisplay(e).text, this.translateDisplay(e).tooltip));
      }
    }
    return resultModel;
  }

  //function to swith names of programm based type of display to normal one and tooltip --> e.g. wfd to World-Fixed-Display
  translateDisplay(variable: string): any {
    switch (variable) {
      case 'wfd':
        return { text: 'World-Fixed-Display', tooltip: 'World-Fixed-Displays sind Bildschirme die feststehend oder fixiert sind. Diese bewegen sich nicht mit dem Nutzer mit.' };
      case 'fishtank':
        return { text: 'Fishtank-Display', tooltip: 'Fishtank-Display gehören zu den World-Fixed-Displays und sind z.B. Computermonitore.' };
      case 'surround':
        return { text: 'Surround-Display', tooltip: 'Surround-Display gehören zu den World-Fixed-Displays und sind z.B. CAVEs.' };
      case 'obd':
        return { text: 'On-Body-Display', tooltip: 'On-Body-Displays sind am Körper getragene oder befestigte Displays. Sie bewegen sich mit dem Nutzer mit.' };
      case 'hmd':
        return { text: 'Head-Mounted-Display', tooltip: 'Head-Mounted-Displays gehören zu den On-Body-Displays und werden am Kopf getragen z.B. als VR-Brille.' };
      case 'seethrough':
        return { text: 'See-Through-Display', tooltip: 'See-Through-Displays gehören zu den Head-Mounted-Displays. Diese Brillen ermöglichen den Blick auf die natürliche Realität wie z.B. AR-Brillen.' };
      case 'nonseethrough':
        return { text: 'Non-See-Through-Display', tooltip: 'Non-See-Through-Displays gehören zu den Head-Mounted-Displays. Diese Brillen verhindern den Blick auf die natürliche Realität wie z.B. VR-Brillen.' };
      case 'earmounted':
        return { text: 'Ear-Mounted-Display', tooltip: 'Ear-Mounted-Display gehören zu den On-Body-Displays und werden am oder durch das Ohr getragen.' };
      case 'overear':
        return { text: 'OverEar-Display', tooltip: 'OverEar-Display gehören zu den Ear-Mounted-Display und werden auf dem Ohr getragen z.B. Schallenkopfhörer.' };
      case 'inear':
        return { text: 'InEar-Display', tooltip: 'InEar-Display gehören zu den Ear-Mounted-Display und werden im Ohr getragen z.B. Kopfhörer.' };
      case 'hhd':
        return { text: 'Hand-Held-Display', tooltip: 'Hand-Held-Display gehören zu den On-Body-Displays und werden in der Hand gehalten wie z.B. Smartphones.' };
      case 'bodymounted':
        return { text: 'On-Body-Display', tooltip: 'On-Body-Displays sind am Körper getragene oder befestigte Displays. Sie bewegen sich mit dem Nutzer mit.' };
      case 'natural':
        return { text: 'Natural-Real-Display', tooltip: 'Natural-Real-Displays entsprichen der Realität. Es gibt keine technische Abstraktion.' };

      default:
        return { text: variable + ' not implemented yet in translate Display', tooltip: 'TODO: Fix this issue!' };
    }
  }


  //function to translate boolean to yes or no
  translateBoolean(variable: any): string {
    if (variable) {
      return 'ja';
    } else {
      return 'nein'
    }
  }

  //Dynamischer Text anhand des Pan-Faktors im Vergleich zum Maximum und Durchschnitt
  getPanText(pan: number, aver: number, max: number): string {
    var txt = 'Ein Vergleich in der Kategorie "' + this.category + '" ergibt, dass diese Variante ';
    if (pan < aver) {
      txt += 'mit ' + (aver - pan) + ' Pan-Punkten unter dem Durchschnitt und ' + (max - pan) + ' Pan-Punkte unter dem Maximum liegen kann. Es ist eine unterdurchschnittliche Anwendung zu erwarten.';
    } else if (pan == aver) {
      txt += ' dem Durchschnitt entsprechen und mit ' + (max - pan) + ' Pan-Punkte unter dem Maximum liegen kann. Es ist eine durchschnittliche Anwendung zu erwarten.';
    } else {
      txt += 'mit ' + (pan - aver) + ' Pan-Punkten über dem Durchschnitt und ';
      if (max > pan) {
        txt += (max - pan) + ' Pan-Punkte unter dem Maximum liegen kann. Es ist eine überdurchschnittliche Anwendung zu erwarten.';
      } else {
        txt += (pan - max) + ' Pan-Punkte über dem Maximum liegen kann. Es ist eine überdurchschnittliche Anwendung zu erwarten, die alle bisherigen Anwendungen übertreffen kann.';
      }
    }
    return txt;
  }

  //function to  get Text from Quality
  readQualities(qualityList: any, quantity: string): Ratingmodell {
    var keys = Object.keys(qualityList);
    var values = Object.values(qualityList);
    for (let k = 0; k < keys.length; k++) {
      const e = keys[k];
      const v = values[k];

      if (v) {
        switch (e) {
          case 'schematisch':
            return new Ratingmodell('Schematisch', quantity, this.pandefinitions);
          case 'einfach':
            return new Ratingmodell('Einfach', quantity, this.pandefinitions);
          case 'abstrakt':
            return new Ratingmodell('Abstrakt', quantity, this.pandefinitions);
          case 'realitaetsnah':
            return new Ratingmodell('Realitätsnah', quantity, this.pandefinitions);
          case 'real':
            return new Ratingmodell('Real', quantity, this.pandefinitions);
        }
      }
    }
    return new Ratingmodell('Keine', 'Keine', this.pandefinitions);
  }

  getStringForMovementAndCommunication(): string {
    // { text: 'Umsetzung der Bewegung als '+this.result_random.bewegung, style: 'text' },
    // { text: 'Umsetzung der Kommunikation als '+this.result_random.kommunikation, style: 'text' },
    var txt = '';
    var help = false;
    if (this.result_random.bewegung != '' && this.result_random.bewegung != undefined) {
      txt += 'Umsetzung der Bewegung als ' + this.result_random.bewegung + '.\n';
    } else {
      help = true;
    }
    if (this.result_random.kommunikation != '' && this.result_random.kommunikation != undefined) {
      txt += 'Umsetzung der Kommunikation als ' + this.result_random.kommunikation + '.\n';
    } else {
      if (help) {
        txt = 'Keine zusätzlichen Empfehlungen vorhanden.';
      }
    }
    return txt;
  }
  //#endregion

  //#region EXPORT PDF

  printPDF() {
    pdfmake.vfs = pdfFonts.pdfMake.vfs;
    var docDef = {
      pageSize: 'A4',
      //[left, top, right, bottom] in inches to convert: cm*0,3937
      pageMargins: [40, 60, 40, 60],
      content: [],
      header: {
        margin: [40, 30, 40, 20],
        columns: [
          {
            text: 'Handlungsempfehlung mittels Pan-Faktor für ' + this.name,
            width: '*',
            alignment: 'left',
            fontSize: 12,
            bold: true
            // style: 'header2',
          },
          {
            image: this.imageHandler.getLogoHelp(),
            width: 40,
            margin: [0, -15, -20, 0],
          }
        ]
      },
      footer: function (currentPage, pageCount) {
        return [
          { text: currentPage.toString() + ' von ' + pageCount, style: 'footer' }
        ];
      },
      info: {
        title: 'Handlungsempfehlung mittels Pan-Faktor für ' + this.name,
        author: 'Dr.-Ing. Christian Eller',
        creator: 'HELP - Webseite zur Erstellung einer Handlungsempfehlung (www.pan-factor.info)',
        producer: 'Dr.-Ing. Christian Eller',
        keywords: '"pan-factor.info", "Pan-Faktor", "Digitale Realität", "Handlungsempfehlung", "' + this.name + '", "' + this.category
      },
      watermark: { text: 'Demonstrator (www.pan-factor.info)', angle: 45, opacity: 0.1, bold: true, italics: false },
      styles: {
        figureText: {
          alignment: 'center',
          fontSize: 10,
        },
        figure: {
          alignment: 'center'
        },
        header2: {
          fontSize: 12,
          bold: true,
          alignment: 'left',
          margin: [20, 0, 20, 0]
        },
        header3: {
          fontSize: 12,
          alignment: 'left',
          margin: [20, 0, 20, 0]
        },
        header4: {
          fontSize: 12,
          alignment: 'right',
          margin: [20, 20, 20, 0]
        },
        footer: {
          fontSize: 11,
          alignment: 'center',
          margin: [20, 20, 20, 0]
        },
        note: {
          fontSize: 8,
          alignment: 'left',
          margin: [20, 0, 20, 0]
        },
        highlighted: {
          bold: true,
          margin: [10, 0, 10, 0]
        },
        header: {
          //font: 'Helvetica',
          bold: true,
          fontSize: 14,
          alignment: 'center',
          decoration: 'underline',
          decorationColor: '#004E8A'
        },
        text: {
          //font: 'Helvetica',
          fontSize: 12,
          alignment: 'justify',
          margin: [10, 0, 10, 0]
        },
        nr: {
          //font: 'Helvetica',
          fontSize: 12,
          alignment: 'justify',
          margin: [30, 0, 30, 0]
        },
        tablePan: {
          fillColor: '#7fab16',
          bold: true
        },
        tableVariante1: {
          // fillColor: '#E9503E',
          fillColor: '#7FAB16',
          // color: '#ffffff',
          alignment: 'center',
          bold: true
        },
        tableVariante2: {
          fillColor: '#7FAB16',
          alignment: 'center',
          bold: true
        },
        tableVariante3: {
          // fillColor: '#7FAB16',
          fillColor: '#7FAB16',
          alignment: 'center',
          // color: '#ffffff',
          bold: true
        },
        pageSize: 'A4',
        pageMargins: [80, 60, 80, 60]
      }
    }
    docDef.content = this.getOptionbasedText();
    pdfmake.createPdf(docDef).download('Handlungsempfehlung_' + this.name + '.pdf');
  }

  getStringForDisplay(displays: TooltipModel[]): string {
    var text = '\n'
    for (let i = 0; i < displays.length; i++) {
      const e = displays[i];
      text += '' + (i + 1) + '.\t' + e.tooltip + '\n';
    }
    if (displays.length == 0) {
      text = '\nFür diesen Typ sind keine Vorschläge vorhanden. Dies liegt daran, da die Art von Nutzung nicht ausgwählt wurde.';
    }
    return text;
  }
  getStringForQuality(sinn: string, quality: Ratingmodell, origQuality: Ratingmodell): string {
    if (origQuality != null) {
      return '' + origQuality.Quality + ' --> ' + quality.Quality + '\n\t' + quality.getDescription(sinn, quality.Quality);
    } else {
      return '' + quality.Quality + '\n\t' + quality.getDescription(sinn, quality.Quality);
    }
  }

  formatDate = function (date) {
    let d = new Date(date),
      _year = d.getFullYear(),
      _month = '' + (d.getMonth() + 1),
      _day = '' + d.getDate();

    if (_month.length < 2)
      _month = '0' + _month;
    if (_day.length < 2)
      _day = '0' + _day;
    return [_day, _month, _year].join('.');
  }

  getOptionbasedText(): any {
    var content = [];
    //Values, which are always the same
    content.push({ text: ' ', style: 'text' });
    content.push({ text: 'Handlungsempfehlung mittels Pan-Faktor (HEL.P.) für Ihre Anwendung (www.pan-factor.info)', style: 'header' });
    content.push({ text: ' ', style: 'text' });
    content.push({
      image: this.imageHandler.getLogoHelp(),
      fit: [500, 500],
      style: 'figure'
    });
    content.push({ text: ' ', style: 'text' });


    //TODO Beschreibungstext einfügen
    content.push({ text: 'HEL.P. ist eine Handlungsempfehlung für Ingenieuranwendungen innerhalb der Digitalen Realität (DR). Über einen interaktiven Fragebogen können Anforderungen an eine Software in Erfahrung gebracht und mit dem Vergleich zu anderen Anwendungen der gleichen Kategorie verschiedene Varianten ausgearbeitet werden. Dabei handelt es sich um eine Empfehlung des geeigneten Mediums, der zu nutzenden Displayarten verschiedener Sinne und die Entwicklungsqualitäten bzw. Detailstufen der verschiedenen Reize. Es wird ein fiktiver Pan-Faktor berechnet oder ein bestehender verwendet um einen Vergleich in der aktuellen Softwarelandschaft heranzuziehen.', style: 'text' });
    content.push({ text: ' ', style: 'text', pageBreak: 'after' });
    content.push({ text: 'Empfehlung für ' + this.name, style: 'header' });
    content.push({ text: ' ', style: 'text' });
    content.push({ text: 'Allgemeine Informationen ', style: 'header2' });
    content.push({ text: ' ', style: 'text' });
    content.push({ text: this.advice, style: 'text' });
    content.push({ text: ' ', style: 'text' });
    content.push({ text: ' ', style: 'text' });

    //Table which is depending on options
    var table = {
      layout: 'noBorders',
      table: {
        headerRows: 1,
        widths: [160, 8, 160, 8, 160],
        body: []
      }
    }

    var count = 0;
    var row1 = [], row2 = [], row3 = [], row4 = [];
    if (this.variante1.panmodell.isValid) {
      row1.push({ text: 'Variante 1', style: 'tableVariante1' });
      row1.push({ text: '', style: '' });
      row2.push({ text: '"Preisgünstig"', style: 'tableVariante1' });
      row2.push({ text: '', style: '' });
      row3.push({ text: 'Möglicher Pan-Faktor', style: 'tableVariante1' });
      row3.push({ text: '', style: '' });
      row4.push({ text: this.variante1.panmodell.Panfactor + ' Pan-Punkte', style: 'tableVariante1' });
      row4.push({ text: '', style: '' });
      count++;
    }
    if (this.variante2.panmodell.isValid) {
      row1.push({ text: 'Variante 2', style: 'tableVariante2' });
      row1.push({ text: '', style: '' });
      row2.push({ text: '"Optimum"', style: 'tableVariante2' });
      row2.push({ text: '', style: '' });
      row3.push({ text: 'Möglicher Pan-Faktor', style: 'tableVariante2' });
      row3.push({ text: '', style: '' });
      row4.push({ text: this.variante2.panmodell.Panfactor + ' Pan-Punkte', style: 'tableVariante2' });
      row4.push({ text: '', style: '' });
      count++;
    }
    if (this.variante3.panmodell.isValid) {
      row1.push({ text: 'Variante 3', style: 'tableVariante3' });
      row2.push({ text: '"Zukunftsorientiert"', style: 'tableVariante3' });
      row3.push({ text: 'Möglicher Pan-Faktor', style: 'tableVariante3' });
      row4.push({ text: this.variante3.panmodell.Panfactor + ' Pan-Punkte', style: 'tableVariante3' });
      count++
    }
    if (count == 2) {
      table.table.widths = [240, 8, 240];
    }

    table.table.body.push(row1);
    table.table.body.push(row2);
    table.table.body.push(row3);
    table.table.body.push(row4);
    content.push(table);

    //Details of each variant
    //VARIANTE 1 -- START
    if (this.variante1.panmodell.isValid) {
      content.push({ text: ' ', style: 'text' });
      content.push({ text: ' ', style: 'text' });
      content.push({ text: ' ', style: 'text' });
      content.push({ text: 'Detailinformationen Variante 1 "Preisgünstig" ', style: 'header' });
      content.push({ text: ' ', style: 'text' });
      content.push({ text: 'Möglicher Pan-Faktor ', style: 'header2' });
      content.push({ text: ' ', style: 'text' });
      content.push({ text: 'Es kann ein Pan-Faktor von ' + this.variante1.panmodell.Panfactor + ' Pan Punkten erreicht werden.', style: 'text' });
      content.push({ text: ' ', style: 'text' });
      content.push({ text: this.variante1.pantext, style: 'text' });
      content.push({ text: ' ', style: 'text' });
      content.push({ text: 'Geeignetes Medium', style: 'header2' });
      content.push({ text: ' ', style: 'text' });
      content.push({ text: this.variante1.media.text, style: 'text' });
      content.push({ text: this.variante1.media.tooltip, style: 'text' });
      content.push({ text: ' ', style: 'text' });
      content.push({ text: ' ', style: 'text', pageBreak: 'before' });
    } else {
      content.push({ text: ' ', style: 'text' });
    }
    //VARIANTE 1 --- ENDE

    //VARIANTE 2 -- START
    content.push({ text: 'Detailinformationen Variante 2 "Optimum" ', style: 'header' });
    content.push({ text: ' ', style: 'text' });
    content.push({ text: 'Möglicher Pan-Faktor ', style: 'header2' });
    content.push({ text: ' ', style: 'text' });
    content.push({ text: 'Es kann ein Pan-Faktor von ' + this.variante2.minPanmodell.Panfactor + ' bis ' + this.variante2.panmodell.Panfactor + ' Pan Punkten erreicht werden.', style: 'text' });
    content.push({ text: ' ', style: 'text' });
    content.push({ text: this.variante2.pantext, style: 'text' });
    content.push({ text: ' ', style: 'text' });
    content.push({ text: 'Geeignetes Medium', style: 'header2' });
    content.push({ text: ' ', style: 'text' });
    content.push({ text: this.variante2.media.text, style: 'text' });
    content.push({ text: this.variante2.media.tooltip, style: 'text' });
    content.push({ text: ' ', style: 'text' });

    if (this.option == 3) {
      content.push({ text: 'Geeignete Displayarten', style: 'header2' });
      content.push({ text: ' ', style: 'text' });

      var displaylist = [];
      var displayImmersionlist = [];
      var displayPresencelist = [];
      displayImmersionlist.push({ text: 'Visuelle Displays:' + this.getStringForDisplay(this.variante2.typ.visuell), style: 'nr' });
      displayImmersionlist.push({ text: 'Auditive Displays:' + this.getStringForDisplay(this.variante2.typ.auditiv), style: 'nr' });
      displayImmersionlist.push({ text: 'Haptische Displays:' + this.getStringForDisplay(this.variante2.typ.haptisch), style: 'nr' });
      displayImmersionlist.push({ text: 'Olfaktorische Displays:' + this.getStringForDisplay(this.variante2.typ.olfaktorisch), style: 'nr' });
      displayImmersionlist.push({ text: 'Gustatorische Displays:' + this.getStringForDisplay(this.variante2.typ.gustatorisch), style: 'nr' });
      displayPresencelist.push({ text: 'Displays zur Steigerung der Präsenz mittels Körpergefühl: ' + this.getStringForDisplay(this.variante2.typ.koerpergefuehl), style: 'nr' });
      displayPresencelist.push({ text: 'Displays zur Steigerung der Präsenz mittels Bewegung: ' + this.getStringForDisplay(this.variante2.typ.bewegung), style: 'nr' });
      displaylist.push([{ ul: displayImmersionlist }, { ul: displayPresencelist }]);
      content.push(displaylist);

      content.push({ text: ' ', style: 'text' });
      content.push({ text: 'Empfohlene Qualitätsumsetzungen', style: 'header2' });
      content.push({ text: ' ', style: 'text' });

      var qualitylist = [];
      var qualityImmersionlist = [];
      var qualityPresencelist = [];
      var qualityInteracitityList = [];
      qualityImmersionlist.push({ text: 'Visuelle Wahrnehmung: ' + this.getStringForQuality('Visuell', this.variante2.panmodell.Immersion.Visually, null), style: 'nr' });
      qualityImmersionlist.push({ text: 'Auditive Wahrnehmung: ' + this.getStringForQuality('Auditiv', this.variante2.panmodell.Immersion.Aurally, null), style: 'nr' });
      qualityImmersionlist.push({ text: 'Haptische Wahrnehmung: ' + this.getStringForQuality('Haptisch', this.variante2.panmodell.Immersion.Haptic, null), style: 'nr' });
      qualityImmersionlist.push({ text: 'Olfaktorische Wahrnehmung: ' + this.getStringForQuality('Olfaktorisch', this.variante2.panmodell.Immersion.Olfactory, null), style: 'nr' });
      qualityImmersionlist.push({ text: 'Gustatorische Wahrnehmung: ' + this.getStringForQuality('Gustatorisch', this.variante2.panmodell.Immersion.Gustatory, null), style: 'nr' });
      qualityPresencelist.push({ text: 'Körpergefühl: ' + this.getStringForQuality('Koerpergefuehl', this.variante2.panmodell.Presense.Bodyawarness, null), style: 'nr' });
      qualityPresencelist.push({ text: 'Bewegung: ' + this.getStringForQuality('Bewegung', this.variante2.panmodell.Presense.Movement, null), style: 'nr' });
      qualityInteracitityList.push({ text: 'Interaktion: ' + this.getStringForQuality('Interaktion', this.variante2.panmodell.Interactivity.Interaction, null), style: 'nr' });
      qualityInteracitityList.push({ text: 'Kommunikation: ' + this.getStringForQuality('Kommunikation', this.variante2.panmodell.Interactivity.Communication, null), style: 'nr' });
      qualitylist.push([{ ul: qualityImmersionlist }, { ul: qualityPresencelist }, { ul: qualityInteracitityList }]);
      content.push(qualitylist);
    }
    if (this.option == 1) {
      content.push({ text: 'Empfohlene Qualitätsumsetzungen', style: 'header2' });
      content.push({ text: ' ', style: 'text' });

      content.push({ text: 'Umsetzung der Immersion', style: 'header3' });
      content.push({ text: ' ', style: 'text' });
      content.push({ text: this.variante2.panmodell.getQualitytext(true).immersion, style: 'text' });
      content.push({ text: ' ', style: 'text' });
      content.push({ text: 'Umsetzung der Präsenz', style: 'header3' });
      content.push({ text: ' ', style: 'text' });
      content.push({ text: this.variante2.panmodell.getQualitytext(true).presence, style: 'text' });
      content.push({ text: ' ', style: 'text' });
      content.push({ text: 'Umsetzung der Wechselwirkung', style: 'header3' });
      content.push({ text: ' ', style: 'text' });
      content.push({ text: this.variante2.panmodell.getQualitytext(true).interactivity, style: 'text' });

      // var qualitylist = [];
      // var qualityImmersionlist = [];
      // var qualityPresencelist = [];
      // var qualityInteracitityList = [];
      // qualityImmersionlist.push({ text: 'Visuelle Wahrnehmung: ' + this.getStringForQuality('Visuell', this.variante2.panmodell.Immersion.Visually, this.variante2.minPanmodell.Immersion.Visually) });
      // qualityImmersionlist.push({ text: 'Auditive Wahrnehmung: ' + this.getStringForQuality('Auditiv', this.variante2.panmodell.Immersion.Aurally, this.variante2.minPanmodell.Immersion.Aurally) });
      // qualityImmersionlist.push({ text: 'Haptische Wahrnehmung: ' + this.getStringForQuality('Haptisch', this.variante2.panmodell.Immersion.Haptic, this.variante2.minPanmodell.Immersion.Haptic) });
      // qualityImmersionlist.push({ text: 'Olfaktorische Wahrnehmung: ' + this.getStringForQuality('Olfaktorisch', this.variante2.panmodell.Immersion.Olfactory, this.variante2.minPanmodell.Immersion.Olfactory) });
      // qualityImmersionlist.push({ text: 'Gustatorische Wahrnehmung: ' + this.getStringForQuality('Gustatorisch', this.variante2.panmodell.Immersion.Gustatory, this.variante2.minPanmodell.Immersion.Gustatory) });
      // qualityPresencelist.push({ text: 'Körpergefühl: ' + this.getStringForQuality('Koerpergefuehl', this.variante2.panmodell.Presense.Bodyawarness, this.variante2.minPanmodell.Presense.Bodyawarness) });
      // qualityPresencelist.push({ text: 'Bewegung: ' + this.getStringForQuality('Bewegung', this.variante2.panmodell.Presense.Movement, this.variante2.minPanmodell.Presense.Movement) });
      // qualityInteracitityList.push({ text: 'Interaktion: ' + this.getStringForQuality('Interaktion', this.variante2.panmodell.Interactivity.Interaction, this.variante2.minPanmodell.Interactivity.Interaction) });
      // qualityInteracitityList.push({ text: 'Kommunikation: ' + this.getStringForQuality('Kommunikation', this.variante2.panmodell.Interactivity.Communication, this.variante2.minPanmodell.Interactivity.Communication) });
      // qualitylist.push([{ ul: qualityImmersionlist }, { ul: qualityPresencelist }, { ul: qualityInteracitityList }]);
      // content.push(qualitylist);
    }

    content.push({ text: ' ', style: 'text' });
    content.push({ text: 'Zusätzliche Hinweise', style: 'header3' });
    content.push({ text: ' ', style: 'text' });
    content.push({ text: this.getStringForMovementAndCommunication(), style: 'text' });
    content.push({ text: ' ', style: 'text' });

    //VARIANTE 2 --- ENDE


    //VARIANTE 3-- START
    if (this.variante3.panmodell.isValid) {
      // content.push({ text: ' ', style: 'text', pageBreak: 'before' });
      content.push({ text: ' ', style: 'text' });
      content.push({ text: 'Detailinformationen Variante 3 "Zukunftsorientiert" ', style: 'header' });
      content.push({ text: ' ', style: 'text' });
      content.push({ text: 'Möglicher Pan-Faktor ', style: 'header2' });
      content.push({ text: ' ', style: 'text' });
      content.push({ text: 'Es kann ein Pan-Faktor von ' + this.variante3.panmodell.Panfactor + ' Pan Punkten erreicht werden.', style: 'text' });
      content.push({ text: ' ', style: 'text' });
      content.push({ text: this.variante3.pantext, style: 'text' });
      content.push({ text: ' ', style: 'text' });
      content.push({ text: 'Geeignetes Medium', style: 'header2' });
      content.push({ text: ' ', style: 'text' });
      content.push({ text: this.variante3.media.text, style: 'text' });
      content.push({ text: this.variante3.media.tooltip, style: 'text' });
      content.push({ text: ' ', style: 'text' });

      if (this.option == 1) {
        //Verbesserungen der Qualität darstellen
        content.push({ text: 'Empfohlene Qualitätsumsetzungen', style: 'header2' });
        content.push({ text: ' ', style: 'text' });

        content.push({ text: 'Umsetzung der Immersion', style: 'header3' });
        content.push({ text: ' ', style: 'text' });
        content.push({ text: this.variante2.panmodell.getQualitytext(true).immersion, style: 'text' });
        content.push({ text: ' ', style: 'text' });
        content.push({ text: 'Umsetzung der Präsenz', style: 'header3' });
        content.push({ text: ' ', style: 'text' });
        content.push({ text: this.variante2.panmodell.getQualitytext(true).presence, style: 'text' });
        content.push({ text: ' ', style: 'text' });
        content.push({ text: 'Umsetzung der Wechselwirkung', style: 'header3' });
        content.push({ text: ' ', style: 'text' });
        content.push({ text: this.variante2.panmodell.getQualitytext(true).interactivity, style: 'text' });
        // var qualitylist = [];
        // var qualityImmersionlist = [];
        // var qualityPresencelist = [];
        // var qualityInteracitityList = [];
        // qualityImmersionlist.push({ text: 'Visuelle Wahrnehmung: ' + this.getStringForQuality('Visuell', this.variante3.panmodell.Immersion.Visually, this.variante3.minPanmodell.Immersion.Visually) });
        // qualityImmersionlist.push({ text: 'Auditive Wahrnehmung: ' + this.getStringForQuality('Auditiv', this.variante3.panmodell.Immersion.Aurally, this.variante3.minPanmodell.Immersion.Aurally) });
        // qualityImmersionlist.push({ text: 'Haptische Wahrnehmung: ' + this.getStringForQuality('Haptisch', this.variante3.panmodell.Immersion.Haptic, this.variante3.minPanmodell.Immersion.Haptic) });
        // qualityImmersionlist.push({ text: 'Olfaktorische Wahrnehmung: ' + this.getStringForQuality('Olfaktorisch', this.variante3.panmodell.Immersion.Olfactory, this.variante3.minPanmodell.Immersion.Olfactory) });
        // qualityImmersionlist.push({ text: 'Gustatorische Wahrnehmung: ' + this.getStringForQuality('Gustatorisch', this.variante3.panmodell.Immersion.Gustatory, this.variante3.minPanmodell.Immersion.Gustatory) });
        // qualityPresencelist.push({ text: 'Körpergefühl: ' + this.getStringForQuality('Koerpergefuehl', this.variante3.panmodell.Presense.Bodyawarness, this.variante3.minPanmodell.Presense.Bodyawarness) });
        // qualityPresencelist.push({ text: 'Bewegung: ' + this.getStringForQuality('Bewegung', this.variante3.panmodell.Presense.Movement, this.variante3.minPanmodell.Presense.Movement) });
        // qualityInteracitityList.push({ text: 'Interaktion: ' + this.getStringForQuality('Interaktion', this.variante3.panmodell.Interactivity.Interaction, this.variante3.minPanmodell.Interactivity.Interaction) });
        // qualityInteracitityList.push({ text: 'Kommunikation: ' + this.getStringForQuality('Kommunikation', this.variante3.panmodell.Interactivity.Communication, this.variante3.minPanmodell.Interactivity.Communication) });
        // qualitylist.push([{ ul: qualityImmersionlist }, { ul: qualityPresencelist }, { ul: qualityInteracitityList }]);
        // content.push( qualitylist);

      }
      //Fußzeile
      content.push({ text: ' ', style: 'text' });
      content.push({ text: ' ', style: 'text' });
      content.push({ text: 'Diese Berechnung wurde von HEL.P. durchgeführt und maschinell am ' + this.formatDate(Date.now()) + ' erstellt.', style: 'note' });
      content.push({ text: 'Entwickelt von Christian Eller 2019-2020 - Institut für Numerische Methoden und Informatik im Bauwesen; TU Darmstadt;', link: 'https://www.iib.tu-darmstadt.de/mitarbeiter_iib/eller.de.jsp', style: 'note' });

    }
    //VARIANTE 3 --- ENDE
    return content;
  }
  //#endregion

  //#region LOAD DATA FROM CSV

  //function to load media an pan definitions from csv
  loadCsvData() {
    this.http.get('../assets/medienempfehlungen.csv', { responseType: 'text' }).subscribe(
      data => {

        if (this.csvMediaAdvice == undefined || this.csvMediaAdvice == null) {
          this.csvMediaAdvice = [];
        }
        //data ist eine zeile
        //Nr. / Name / AnzahlSinne / Immersion / Präsenz / Interaktion / PanFaktor / Bereich
        parser.parse(data, {
          header: true, skipEmptyLines: true, complete: (result, file) => {
            this.csvMediaAdvice = result.data;
          }
        });

      }
    )
    this.http.get('../assets/panfaktordefinitionen.csv', { responseType: 'text' }).subscribe(
      definitions => {

        if (this.pandefinitions == undefined || this.pandefinitions == null) {
          this.pandefinitions = [];
        }
        //data ist eine zeile
        //Sinn; Qualitaet; Erklaerung
        parser.parse(definitions, {
          header: true, skipEmptyLines: true, complete: (result, file) => {
            this.pandefinitions = result.data;
          }
        });

      }
    )
  }

  //#endregion

  //#region KLEINE HELPER

  //get all Values form media in table
  getAdviceByMedia(media: string): any {
    for (let k = 0; k < this.csvMediaAdvice.length; k++) {
      const element = this.csvMediaAdvice[k];
      // console.log('getAdviceByMedia: compare ' + media + ' with ' + element.Medium);
      if (element.Medium == media) {
        // console.log('return medium ' + media);
        return element;
      }
    }
    return {
      Medium: 'Keine Auswahl möglich.',
      Preisguenstiger: 'Keine Auswahl möglich.',
      Optimum: 'Keine Auswahl möglich.',
      Zukunftsorientiert: 'Keine Auswahl möglich.',
      Erklaerung: 'Keine nähere Erläuterung vorhanden.',
      PanFaktor: 0,
      Durchschnitt: 0,
      Max: 0,
      Min: 0
    };
  }

  //Set next step for progress --> if it is 100 % than it is finish
  nextStep(step) {
    this.complete.steps = Math.round((step / this.complete.count) * 100);
    if (this.complete.steps >= 100) {
      this.complete.finish = true;
    }
  }

  //Reset all lists and name
  reset() {
    this.name = 'Neue Anwendung';
    this.category = 'Ausbildung';
    this.result_senses.reset();
    this.result_quality.reset();
    this.result_media.reset();
    this.result_display.reset();
  }

  //load vr4teach demo --> never used doesnot work
  loadDemo() {
    this.freeHands = true;
    this.oneHourUse = false;

    //set aller results
    this.result_senses.loadDemo();
    this.result_quality.loadDemo();
    this.result_media.loadDemo();
    this.result_display.loadDemo();

    this.variante1.loadDemo();
    this.variante2.loadDemo();
    this.variante3.loadDemo();
    console.log('Load Demo "VR4Teach"');
    this.calculateAdvice();
  }

  debugAdvice() {
    console.log('Category:')
    console.log(this.category);
    console.log('Vergleich:')
    console.log(this.result_compare);
    console.log('Medien:')
    console.log(this.result_media);
    console.log('Display:')
    console.log(this.result_display);
    console.log('Qualität:')
    console.log(this.result_quality);
    console.log('Random:')
    console.log(this.result_random);
  }
  //#endregion
}

