import { Component, OnInit } from '@angular/core';
import { AdviceService } from '../services/advice.service';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-qtn-media',
  templateUrl: './qtn-media.component.html',
  styleUrls: ['./qtn-media.component.css']
})
export class QtnMediaComponent implements OnInit {

  step_media: 'FormGroup';
  helpBoolean: boolean;

  constructor(public adviceService: AdviceService) { }

  ngOnInit() {
    this.helpBoolean = null;
    // this.change21();
    // this.change22();
    // this.change222();
    // this.change2221();
    // this.change2222()
    // this.change2211();
    // this.change22111();
    // this.change221111();
    // this.change221();
  }
  //Media
  //oned twod photo, video degreePhoto degreeVideo vr av arStatic ar mr

  change21() {
    this.adviceService.result_media.oned = !this.adviceService.result_media.twod;

    this.adviceService.result_media.threed = this.adviceService.result_media.twod;
    this.adviceService.result_media.video = this.adviceService.result_media.twod;
    this.adviceService.result_media.photo = this.adviceService.result_media.twod;
    this.adviceService.result_media.degreePhoto = this.adviceService.result_media.twod;
    this.adviceService.result_media.degreeVideo = this.adviceService.result_media.twod;
    this.adviceService.result_media.vr = this.adviceService.result_media.twod;
    this.adviceService.result_media.ar = this.adviceService.result_media.twod;
    this.adviceService.result_media.arStatic = this.adviceService.result_media.twod;
    this.adviceService.result_media.av = this.adviceService.result_media.twod;
    this.adviceService.result_media.mr = this.adviceService.result_media.twod;
    this.adviceService.nextStep(3);
  }

  change22() {
    this.adviceService.result_media.threed = this.adviceService.result_media.mr;
    this.adviceService.result_media.vr = this.adviceService.result_media.mr;
    this.adviceService.result_media.ar = this.adviceService.result_media.mr;
    this.adviceService.result_media.av = this.adviceService.result_media.mr;

    this.adviceService.result_media.photo = !this.adviceService.result_media.mr;
    this.adviceService.result_media.video = !this.adviceService.result_media.mr;
    this.adviceService.result_media.degreePhoto = !this.adviceService.result_media.mr;
    this.adviceService.result_media.degreeVideo = !this.adviceService.result_media.mr;
  }

  change221() {
    this.adviceService.result_media.vr = !this.adviceService.result_media.threed;
    this.adviceService.result_media.av = !this.adviceService.result_media.threed;
    this.adviceService.result_media.arStatic = !this.adviceService.result_media.threed;
    this.adviceService.result_media.degreePhoto = !this.adviceService.result_media.threed;
    this.adviceService.result_media.degreeVideo = !this.adviceService.result_media.threed;
    this.adviceService.result_media.ar = !this.adviceService.result_media.threed;
    this.adviceService.result_media.photo = !this.adviceService.result_media.threed;
    this.adviceService.result_media.video = !this.adviceService.result_media.threed;
  }

  change2211() {
    //AR
    this.adviceService.result_media.vr = !this.adviceService.result_media.ar;
    this.adviceService.result_media.av = !this.adviceService.result_media.ar;
  }

  change22111() {
    //AR Static
    this.adviceService.result_media.vr = !this.adviceService.result_media.arStatic;
    this.adviceService.result_media.av = !this.adviceService.result_media.arStatic;
    this.adviceService.result_media.photo = this.adviceService.result_media.arStatic;
    this.adviceService.result_media.video = this.adviceService.result_media.arStatic;
    this.adviceService.result_media.degreePhoto = !this.adviceService.result_media.arStatic;
    this.adviceService.result_media.degreeVideo = !this.adviceService.result_media.arStatic;
  }

  change221111() {
    this.adviceService.result_media.vr = !this.adviceService.result_media.av;
    //Beziehung zu 1.2 bei der Displays festgelegt werden wenn kleiner als 60 min
    this.adviceService.result_display.visuell.hmd = this.adviceService.oneHourUse;
    this.adviceService.result_display.visuell.wfd = !this.adviceService.oneHourUse;
    this.adviceService.result_display.visuell.seethrough = this.adviceService.oneHourUse;
    this.adviceService.result_display.visuell.nonseethrough = this.adviceService.oneHourUse;
  }

  change222() {
    this.adviceService.result_media.video = this.helpBoolean;
    this.adviceService.result_media.degreeVideo = this.adviceService.result_media.video;
  }

  change2221() {
    //Video oder 360° Video
    this.adviceService.result_media.video = !this.adviceService.result_media.degreeVideo;
    this.adviceService.result_media.photo = false;
    this.adviceService.result_media.degreePhoto = false;
  }

  change2222() {
    //Foto oder 360° Foto
    this.adviceService.result_media.photo = !this.adviceService.result_media.degreePhoto;
    this.adviceService.result_media.video = false;
    this.adviceService.result_media.degreeVideo = false;
  }
}
